<template>
<div class="main">
    <section class="section pt-5" ref="section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <transition name="fade">
                        <div class="page-content" v-if="loading">
                            <div class="d-flex text-white">
                                <i class="fas fa-spinner fa-spin fa-6x mx-auto mt-3 mb-4"></i>
                            </div>
                            <h4 class="text-center mb-3">Loading. . .</h4>
                        </div>
                        <div class="row match-height" v-else>
                            <div class="col-md-4">
                                <div class="card orange sticky p-lg-4" :class="{'mb-0': !mobile}" data-top="5%">
                                    <div class="flex-column d-block">
                                        <div class="form-group">
                                            <input type="search" v-model="search" class="form-control" placeholder="Search here" />
                                        </div>
                                        <hr>
                                        <transition name="fade">
                                            <div v-if="l_loading">
                                                <div class="d-flex text-white">
                                                    <i class="fas fa-spinner fa-spin fa-6x mx-auto mt-3 mb-4"></i>
                                                </div>
                                                <h4 class="text-center mb-3">Loading. . .</h4>
                                            </div>
                                            <div class="py-5" v-else-if="!chats_filtered.length && !l_loading">
                                                <talk-box v-for="p,i in pandits" :pandit="p" :vertical="true" :class="'vertical-box'"></talk-box>
                                                <!-- <i class= "fas fa-face-thinking fa-6x mb-5"></i>
                                                <h4 class="text-center mb-3">No Messages Yet. . .</h4> -->
                                            </div>
                                        </transition>
                                        <transition-group tag="div" v-if="chats_filtered.length" class="chat_list_container px-2" name="fade">
                                            <div @click="openchat(chat.doc_id)" :class="{ 'active' : chat.doc_id == current_chat_id }" class="chat-list" v-for="(chat, ind) in chats_filtered" :key="ind">
                                                <div class="img-container">
                                                    <img v-if="chat.to.image" :src="chat.to.image" alt="">
                                                    <img v-else src="/app-assets/images/user.png">
                                                </div>
                                                <div class="block">
                                                    <div class="info">
                                                        <span class="name">{{ chat.to.name }}</span>
                                                    </div>
                                                    <div class="message">
                                                        {{ chat.recentMsg ?? 'No Recent Message!' }}
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <!-- <span class="time">{{ chat.recentMsgTimestamp ? $filters.UnixTS(chat.recentMsgTimestamp) : $filters.UnixTS(chat.chatTimestamp) }}</span> -->
                                                    <div class="ms-auto" v-if="chat.unseen">
                                                        <span class="unseen">{{ chat.unseen }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </transition-group>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 position-relative">
                                <div class="chat-container px-4">
                                    <transition-group name="fade">
                                        <div class="chat-head" key="head" v-if="current_user">
                                            <router-link class="user-info" :to="'/astrologer/'+current_user.username">
                                                <div class="position-relative">
                                                    <img v-if="current_user.image" :src="current_user.image" :alt="current_user.name">
                                                    <img v-else src="/app-assets/images/h-logo.png">
                                                    <span v-if="current_session" class="status online"></span>
                                                </div>
                                                <div class="wrapper">
                                                    <div class="name">{{ current_user.name }}</div>
                                                    <div class="price">{{ 'Chat @ '+user.currency.symbol + chat_price }}</div>
                                                </div>
                                                <!-- <div class="d-flex flex-column">
                                                    <div class="icons">
                                                        <div class="box"><i class="fas fa-eye"></i> {{ numberShort(current_user.prompts_sum_view_count,1) }}</div>
                                                        <div class="box"><i class="fas fa-heart"></i> {{ numberShort(current_user.likes_count,1) }}</div>
                                                        <div class="box"><i class="fas fa-tag"></i> {{ current_user.rank }}</div>
                                                    </div>
                                                </div> -->
                                            </router-link>
                                            <div class="flex-cc gap-1">
                                                <div id="timer"></div>
                                                <!-- <router-link to="" class="btn dark round small">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="me-0" width="25" height="26" viewBox="0 0 25 26" fill="none">
                                                        <path d="M3.23047 6.55225C3.23047 5.41235 4.15454 4.48828 5.29444 4.48828H19.7305C20.0066 4.48828 20.2305 4.71214 20.2305 4.98828V6.61622C20.2305 7.72079 19.335 8.61622 18.2305 8.61622H5.29444C4.15454 8.61622 3.23047 7.69215 3.23047 6.55225Z" fill="#FF7A4A"/>
                                                        <path d="M3.23047 16.9986V6.55078C3.23047 7.69068 4.15454 8.61475 5.29444 8.61475H19.2305C20.335 8.61475 21.2305 9.51018 21.2305 10.6148V13.6247C21.2305 13.7075 21.1633 13.7747 21.0805 13.7747H18.2305C17.1259 13.7747 16.2305 14.6701 16.2305 15.7747V15.9026C16.2305 17.0072 17.1259 17.9026 18.2305 17.9026H21.0805C21.1633 17.9026 21.2305 17.9698 21.2305 18.0526V18.9986C21.2305 20.1031 20.335 20.9986 19.2305 20.9986H7.23047C5.02133 20.9986 3.23047 19.2077 3.23047 16.9986Z" fill="#FFECE2"/>
                                                        <path d="M15.2305 15.7734C15.2305 14.6689 16.1259 13.7734 17.2305 13.7734H21.0805C21.1633 13.7734 21.2305 13.8406 21.2305 13.9234V17.7514C21.2305 17.8342 21.1633 17.9014 21.0805 17.9014H17.2305C16.1259 17.9014 15.2305 17.006 15.2305 15.9014V15.7734Z" fill="#FF7A4A"/>
                                                        <rect x="5.23047" y="10.3594" width="6" height="1" rx="0.5" fill="#FF7A4A"/>
                                                    </svg>
                                                </router-link> -->
                                                <button type="button" @click="emitter.emit('showAVmodal')" class="btn light round small">
                                                    <i class="fas me-0 fa-phone"></i>
                                                </button>
                                                <button type="button" @click="emitter.emit('showAVmodal')" class="btn light round small">
                                                    <i class="fas me-0 fa-video"></i>
                                                </button>
                                                <button type="button" class="btn light round small" v-if="!current_session && !invite_sent" @click="chat_initiate()">
                                                    <i class="fas fa-message"></i>
                                                    Start Chat
                                                </button>
                                                <button type="button" class="btn light round small" v-else-if="current_session && !invite_sent" @click="end_chat()">
                                                    <i class="fas fa-times"></i>
                                                    End Chat
                                                </button>
                                                <button type="button" class="btn light round small" v-else>
                                                    <i class="fas fa-clock"></i>
                                                    Invited
                                                </button>
                                                <!-- <button type="button" class="btn light round small" @click="cancel_invite()" v-else>
                                                    <i class="fas fa-clock"></i>
                                                    Cancel Invite
                                                </button> -->
                                                <button type="button" @click="close_chat()" class="btn light round small">
                                                    <i class="fas me-0 fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="chat-body" key="container">
                                            <transition name="fade" mode="out-in">
                                                <div class="content" v-if="c_loading">
                                                    <i class="fas fa-spinner fa-spin fa-6x mb-5"></i>
                                                    <h4 class="text-center mb-3">Chat Loading. . .</h4>
                                                </div>
                                                <div class="content" v-else-if="!c_loading && current_chat_id && messages.length == 0">
                                                    <i class="fas fa-frown fa-6x mb-5"></i>
                                                    <h4 class="text-center mb-3">No Messages Yet. . .</h4>
                                                </div>
                                                <div class="content" v-else-if="!current_session && !current_user">
                                                    <i class="fas fa-face-thinking fa-6x mb-5"></i>
                                                    <h4 class="text-center mb-3">Open any chat. . .</h4>
                                                </div>
                                                <transition-group tag="div" class="scroll_container w-100" v-else-if="!c_loading && current_chat_id && chat_reverse.length" name="fade">
                                                    <div class="text-center" v-if="msg_loading">
                                                        <i class="fas fa-spinner fa-spin fa-3x my-3 text-white"></i>
                                                    </div>
                                                    <div v-for="(m,ind) in chat_reverse" :key="ind">
                                                        <div class="message-block" :class="{'chat-right' : m.senderId == user.id }">
                                                            <span v-if="m.messageType == 'string'" class="message">{{ m.message }}</span>
                                                            <div class="file-msg" v-else-if="m.messageType == 'file'">
                                                                <div class="head">
                                                                    <span class="name">{{ m.fileName }}</span>
                                                                    <span class="size">{{ Math.round(((m.fileSize/(1024)) + Number.EPSILON) * 100) / 100 }} KB</span>
                                                                </div>
                                                                <a :href="m.message" target="_blank" download=""><i class="fas fa-file-download"></i></a>
                                                            </div>
                                                            <span class="time">{{ $filters.UnixTS(m.messageTimestamp) }}</span>
                                                        </div>
                                                        <hr v-if="chat_reverse[ind+1] && chat_reverse[ind+1].sessionId != chat_reverse[ind].sessionId">
                                                    </div>
                                                </transition-group>
                                            </transition>
                                        </div>
                                        <form @submit.prevent="send_message" v-if="current_session?.startTimestamp && !current_session?.endTimestamp" key="chat_footer" class="chat-footer">
                                            <div class="input-group input-group-merge me-1 form-send-message">
                                                <input type="text" :disabled="uploading" class="form-control message" v-model="text" placeholder="Type your message here. . . ">
                                            </div>
                                            <button type="submit" class="btn orange-dark">
                                                <svg v-if="!uploading" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
                                                <i class="fas fa-spin fa-spinner me-0" v-else></i>
                                            </button>
                                        </form>
                                    </transition-group>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </section>
    <!-- <span class="input-group-text" @click="speech"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mic cursor-pointer"><path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path><path d="M19 10v2a7 7 0 0 1-14 0v-2"></path><line x1="12" y1="19" x2="12" y2="23"></line><line x1="8" y1="23" x2="16" y2="23"></line></svg></span> -->
    <!-- <span class="input-group-text">
        <label for="attach-doc" class="attachment-icon form-label mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image cursor-pointer text-secondary"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
            <input :disabled="uploading" type="file" id="attach-doc" @change="handleFileUpload" hidden="">
        </label>
    </span> -->
</div>
</template>
<script>
import { collection, query , onSnapshot, orderBy, getDocs, where, startAfter, setDoc, updateDoc, limit, doc, addDoc, deleteDoc, getDoc} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import leoProfanity from 'leo-profanity';
export default {
    name: "Chat",
    props: ['user', 'pandits'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            chats: [],
            chat_settings: null,
            prices: [],
            messages: [],
            chat_id: false,
            session_id: false,
            docRef: null,
            current_session: null,
            timerInterval: null,
            start_time: '',
            sessionSnapshot:null,
            expectedTimeInMinutes: '',
            remainingTimeInSeconds: '',
            endTime: '',
            search: '',
            l_loading: true,
            loading: false,
            panditDocRef: null,
            panditSessionRef: null,
            listening: false,
            invite_sent: false,
            chat_reverse: [],
            current_user: false,
            current_chat_id: false,
            msg_loading: false,
            c_loading: false,
            uploading: false,
            invite_sent_time: false,
            text: '',
            msgsSnapshot: '',
            last_limit: null,
        };
    },
    async created() {
        await this.fetch_chats()

        if (this.$route.params.id && this.$route.params.id != undefined) {
            this.chat_id = this.$route.params.id ;
            this.openchat(this.chat_id)
        }

        this.emitter.emit('talk_section', true)
        this.emitter.emit('blog_section', true)

        this.emitter.on('loggedOut', () => {
            this.token = null;
        })
    },
    computed: {
        chats_filtered() {
            if(this.search)
                return this.chats.filter(i => i.to.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
            else
                return this.chats
        },
        chat_price() {
            if(this.user.chat_logs_count < this.chat_settings.free_count) {
                return 0 ;
            }
            if(this.user.currency.code == 'INR')
                return this.prices.find(i => i.id == this.current_user.id).chat_price_INR
            else
                return this.prices.find(i => i.id == this.current_user.id).chat_price_USD
        },
        free_session() {
            return this.user.chat_logs_count < this.chat_settings.free_count
        },
    },
    mounted() {
        this.emitter.emit('scroll_section')
        leoProfanity.loadDictionary('en');
        window.addEventListener('beforeunload', this.beforeUnloadHandler);
    },
    beforeUnmount() {
        if (this.timerInterval) clearInterval(this.timerInterval);
        if(this.msgsSnapshot) this.msgsSnapshot()
        if(this.chatsSnapshot) this.chatsSnapshot()
        this.emitter.emit('talk_section', false)
        this.emitter.emit('blog_section', false)
        window.removeEventListener('beforeunload', this.beforeUnloadHandler);
    },
    beforeRouteLeave(to, from, next) {
        if (this.invite_sent) {
            Swal.fire({
                title: "Active Chat Invitation!",
                text: `You have initiated a chat session. Please wait for Astrologer Response!`,
                icon: 'info',
                showCancelButton: false,
                confirmButtonText: "OK!",
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: "#d33",
            })
            // const answer = window.confirm('You have unsaved changes. Do you really want to leave?');
            // if (answer) next(); 
            // else 
            next(false);
        }
        else if (this.current_session) {
            Swal.fire({
                title: "Active Chat Session!",
                text: `You have an active chat session. Unable to move ahead!`,
                icon: 'info',
                showCancelButton: false,
                confirmButtonText: "OK!",
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: "#d33",
            })
            next(false);
        } 
        else next();
    },
    methods: {
        async fetch_chats() {
            var chats_list ;
            var q = query(collection(db, "chats"), where('from.id', '==', this.user.id))
            this.chatsSnapshot = onSnapshot(q, (querySnapshot) => {
                this.chats = []
                chats_list = []
                querySnapshot.forEach((doc) => {
                    var docdata = doc.data() ;
                    docdata['doc_id'] = doc.id
                    chats_list.push(docdata)
                });
            })
            await new Promise(resolve => setTimeout(resolve, 500));
            this.chats = chats_list
            this.l_loading = false
            await new Promise(resolve => setTimeout(resolve, 500));
            await this.index()
        },
        async index() {
            var localData = JSON.parse(localStorage.getItem('current_session') ?? '{}')
            await axios.post('/api/user/chat-index', {'session_id': localData.sessionId ?? null}, this.config).then((res) => {
                this.prices = res.data.prices
                this.chat_settings = res.data.chat_settings
                if(res.data.status == 'success') {
                    if(res.data.continue) {
                        this.session_id = localData.sessionId
                        if(res.data.chat_status == 'started') {
                            this.timeJSON = JSON.parse(localStorage.getItem('timeJSON'))
                            this.start_time = this.timeJSON.start_time
                            this.expectedTimeInMinutes = this.timeJSON.expectedTimeInMinutes
                            this.endTime = this.timeJSON.endTime
                            this.remainingTimeInSeconds = this.timeJSON.remainingTimeInSeconds
                            this.current_session = localData
                            this.panditDocRef = doc(db, `pandits/${localData.pandit_doc_id}`);
                            this.panditSessionRef = doc(db, `pandits/${localData.pandit_doc_id}/sessions/${localData.pandit_session_id}`);
                            this.openchat(localData.current_chat_id)
                            this.timer()
                        }
                        else {
                            this.current_chat_id = localData.current_chat_id;
                            this.docRef = doc(db, "chats", this.current_chat_id);
                            this.current_user = this.chats.find(i => i.doc_id == this.current_chat_id).to
                            this.invite_sent = true
                            this.fetch_chat_status()
                        }
                    }
                    else {
                        localStorage.removeItem('current_session')
                        localStorage.removeItem('timeJSON')
                    }
                }
            })
        },
        async openchat(id) {

            if(id == this.current_chat_id) return ;
            var localData = JSON.parse(localStorage.getItem('current_session') ?? '{}')
            if(localData.current_chat_id && localData.current_chat_id != id) {
                toast.fire({'icon': 'info', 'title': 'Chat Session Active!'})
                return ;
            }

            else { 
                this.messages = this.chat_reverse = []
                this.current_user = false
            }

            this.c_loading = true;
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.$Progress.start()

            this.current_chat_id = id;
            this.docRef = doc(db, "chats", id);
            this.current_user = this.chats.find(i => i.doc_id == id).to

            var colRef = query(collection(this.docRef, 'messages'), orderBy("messageTimestamp","desc"), limit(100))
            this.msgsSnapshot = onSnapshot(colRef, (querySnapshot) => {
                this.messages = []
                querySnapshot.forEach((document) => {
                    var docdata = document.data();
                    this.messages.push(docdata);
                });
                this.last_limit = querySnapshot.docs[querySnapshot.docs.length-1];
                this.c_loading = false
                this.loading = false
                this.chat_reverse = this.messages.reverse();
                this.seen_messages(this.current_chat_id)
                setTimeout(() => {
                    $('body').find('.scroll_container').animate({ scrollTop: $('body').find('.scroll_container')[0].scrollHeight }, 400);
                    $('body').find('.scroll_container')[0].addEventListener('scroll', this.chat_scroll);
                }, 1000);
            });

            this.c_loading = false
            this.loading = false
            this.$Progress.finish();
        },
        close_chat(){
            if(this.current_session != null) {
                toast.fire({'icon': 'info', 'title': 'Chat Session Active!'})
                return ;
            }
            this.current_chat_id = null
            this.current_user = false
            this.messages = []
            this.msgsSnapshot()
        },
        async cancel_invite() {
            await axios.post('/api/user/chat-cancel', {pandit: this.session_id}, this.config).then(async (res) => {
                if(res.data.status == 'success') {
                    await deleteDoc(doc(this.panditDocRef, "sessions", this.panditSessionRef.id));
                    this.invite_sent = false
                }
            });
        },
        async chat_initiate() {
            if(!this.user.completed) {
                toast.fire({icon: 'error', title: 'Please Complete Your Profile. Unable to start Chat!'})
                $this.router.push('/my-profile')
                return ;
            }
            if(this.user.wallet_amount <= this.current_user.chat_price) {
                toast.fire({icon: 'error', title: 'Low Balance. Unable to start Chat!'})
                return ;
            }
    
            if(this.free_session)
                this.expectedTimeInMinutes = this.chat_settings.free_minutes
            else {
                var max_minutes = Math.floor(this.user.wallet_amount / this.chat_price);
                this.expectedTimeInMinutes = Math.min(max_minutes, this.chat_settings.paid_minutes_max)
            }

            var obj
            if(this.free_session) {
                obj = {
                    title: "Congratulations!",
                    html: `This chat is free on us. You have ${this.expectedTimeInMinutes} minutes!`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: "Yes, Start Chat!",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                }
            }
            else  {
                obj = {
                    title: "Sure to chat?",
                    html: `You have <b>${this.user.currency.symbol}</b>${this.user.wallet_amount} in wallet to chat for next ${this.expectedTimeInMinutes} minutes!`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: "Yes, Start Chat!",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                }
            }

            await Swal.fire(obj).then(async (result) => {
                if (result.isConfirmed) {
                    await this.start_chat()
                }
            })
        },
        async start_chat() {
            await axios.post('/api/user/chat-start', {pandit: this.current_user.id, device_type: 3, chat_id: this.current_chat_id}, this.config).then(async (res) => {
                if(res.data.status == 'success') {
                    this.session_id = res.data.session_id
                    this.fetch_chat_status()
                    toast.fire({icon: 'info', title: 'Chat Invitation has been sent to Astrologer. Please Wait!'})
                    var current_session = {
                        sessionId : this.session_id,
                        current_chat_id: this.current_chat_id,
                        status : 'invited',
                        chatPrice: this.chat_price,
                    }
                    this.current_session = current_session
                    localStorage.setItem('current_session', JSON.stringify(current_session))
                    this.invite_sent = true
                    this.invite_sent_time = res.data.invite_sent_time
                } 
                else {
                    toast.fire({'icon': 'error', 'title': res.data.message}) ;
                }
            })
        },
        async fetch_chat_status() {
            try {
                var chk = query(collection(db, "pandits"), where('id', '==', this.current_user.id)) ;
                const querySnapshot = await getDocs(chk);
                if (!querySnapshot.empty) {
                    const docSnap = querySnapshot.docs[0];
                    this.panditDocRef = doc(db, "pandits", docSnap.id)
                } else {
                    this.panditDocRef = await addDoc(collection(db, "pandits"), {
                        id: this.current_user.id, name: this.current_user.name, image: this.current_user.image || '/app-assets/images/user.png',
                    })
                }

                let momentTimestamp = moment(this.invite_sent_time);
                let invite_expiry_time = momentTimestamp.add(this.expectedTimeInMinutes, 'minutes').format('YYYY-MM-DD HH:mm:ss');

                chk = query(collection(this.panditDocRef, "sessions"), where('session_id', '==', this.session_id), where('status', '==', 'invited')) ;
                const inviteSnapshot = await getDocs(chk);
                if (!inviteSnapshot.empty) {
                    const docSnap = inviteSnapshot.docs[0];
                    this.panditSessionRef = doc(this.panditDocRef, "sessions", docSnap.id)
                } else {
                    this.panditSessionRef = await addDoc(collection(this.panditDocRef, "sessions"), {
                        'session_id': this.session_id, 'status': 'invited', 'chat_id': this.current_chat_id, 'invite_expiry_time': invite_expiry_time
                    })
                }
            } catch (error) {
                console.log(error);                      
            }

            const sessionDocRef = doc(this.panditDocRef, "sessions", this.panditSessionRef.id);

            const expirationTimeout = setTimeout(async () => {
                const sessionSnapshot = await getDoc(sessionDocRef);
                if (sessionSnapshot.exists()) {
                    const sessionData = sessionSnapshot.data();
                    if (sessionData.status === 'invited') {
                        // await updateDoc(sessionDocRef, { status: 'invitation_expired' });
                        // localStorage.removeItem('current_session');
                        this.invite_sent = false;
                        await this.end_chat('invitation_expired')
                    }
                }
            }, 1 * 60 * 1000);
        
            this.sessionSnapshot = onSnapshot(sessionDocRef, async (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const sessionData = docSnapshot.data();
                    if (sessionData.status === 'started') {
                        this.sessionSnapshot()
                        setTimeout(async () => {
                            await axios.post('/api/user/chat-get-status', {session_id: this.session_id}, this.config).then(async (res) => {
                                if (res.data.call_status == 'started') {
                                    this.invite_sent = false
                                    this.start_time = res.data.start_time
                                    this.endTime = res.data.end_time;
                                    this.remainingTimeInSeconds = res.data.expectedTimeInSeconds;
                                    this.timer()
                
                                    var current_session = JSON.parse(localStorage.getItem('current_session'))
                                    current_session.startTimestamp = this.start_time,
                                    current_session.endTimestamp = false,
                                    current_session.status = 'started',
                                    current_session.durationInSeconds = this.remainingTimeInSeconds,
                                    current_session.pandit_doc_id = this.panditDocRef.id,
                                    current_session.pandit_session_id = this.panditSessionRef.id,
                                    this.current_session = current_session
                                    localStorage.setItem('current_session', JSON.stringify(current_session))            
                                }                                
                            }).catch ((error) => {
                                toast.fire({icon: 'error', title: 'Error fetching chat status!'})
                            })
                        }, 1000);
                    }
                    else if(sessionData.status === 'rejected') {
                        this.sessionSnapshot()
                        this.invite_sent = false
                        this.current_session = null
                        localStorage.removeItem('current_session')
                        Swal.fire({
                            title: "Chat Invitation Declined!",
                            text: `We are so sorry that your invitation is declined. Please try again later!`,
                            icon: 'info',
                            showCancelButton: false,
                            confirmButtonText: "OK!",
                            confirmButtonColor: "#3085d6",
                            // cancelButtonColor: "#d33",
                        })
                    }
                } else {
                    console.log("Session document not found.");
                }
            }, (error) => {
                console.error("Error listening for status changes:", error);
            });
        },
        timer() {
            this.updateTimerDisplay(this.remainingTimeInSeconds);
            this.timerInterval = setInterval(async () => {
                this.remainingTimeInSeconds--;
                this.updateTimerDisplay(this.remainingTimeInSeconds);
                if (this.remainingTimeInSeconds === 300) { // 5 minutes before expiry
                    Swal.fire({
                        title: "Alert!",
                        text: "Chat will expire in 5 minutes.",
                        icon: "info",
                    });
                }
                if (this.remainingTimeInSeconds == 0) {
                    clearInterval(this.timerInterval);
                    document.getElementById("timer").textContent = "";
                    await this.end_chat('timer_expired')
                }
            }, 1000);
        },
        updateTimerDisplay(remainingTime) {
            localStorage.setItem('timeJSON', JSON.stringify({
                start_time: this.start_time,
                expectedTimeInMinutes: this.expectedTimeInMinutes,
                endTime: this.endTime,
                remainingTimeInSeconds: this.remainingTimeInSeconds
            }))

            this.remainingTimeInSeconds = remainingTime
            // var timeJSON = JSON.parse(localStorage.getItem('timeJSON'))
            // timeJSON.remainingTimeInSeconds = this.remainingTimeInSeconds
            // localStorage.setItem('timeJSON', JSON.stringify(timeJSON))
            var minutes = Math.floor(this.remainingTimeInSeconds / 60);
            var seconds = this.remainingTimeInSeconds % 60;
            var formattedMinutes = String(minutes).padStart(2, '0');
            var formattedSeconds = String(seconds).padStart(2, '0');
            $("#timer").html(formattedMinutes+':'+formattedSeconds);
        },
        send_message() {
            if(this.text == '') return

            if(leoProfanity.check(this.text)) {
                toast.fire({icon: 'info', messgae: 'Please write valid message.'}) ;
                return ;
            }

            const colRef = collection(this.docRef, 'messages')
            
            var str = Date.now().toString() ;
            setDoc(doc(colRef, str), {
                "senderId" : this.user.id,
                "message" : this.text,
                "sessionId" : this.session_id,
                "messageType" : 'string',
                "messageTimestamp" : str,
                "seen" : 0,
            })
            updateDoc(this.docRef, {
                "recentMsgBy" : this.user.id,
                "recentMsg" : this.text,
                "recentMsgType" : 'string',
                "recentMsgTimestamp" : str,
            })
            
            this.text = ''
            this.seen_messages()
            $('body').find('.scroll_container .message-block:last-child')[0].scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
        },
        async seen_messages(id){
            var colRef = collection(this.docRef, 'messages')

            var q = query(colRef, where("seen", "==", 0), where("senderId", "!=", this.user.id));
            const qs = await getDocs(q);
            qs.forEach((ele) => {
                if(ele.data().by != this.user.id){
                    updateDoc(doc(colRef, ele.id), {
                        "seen" : 1
                    })
                }
            });
            // this.chats.find(i => i.chat_id == id).unseen = 0 
        },
        async end_chat(type = null){
            let userMessageCount = 0;
            let panditMessageCount = 0;

            this.chat_reverse.filter(i => i.session_id == this.session_id).forEach(ele => {
                if (message.senderId === this.user.id) {
                    userMessageCount++;
                } else {
                    panditMessageCount++;
                }
            })
            var end_by = type ?? 'customer'
            var data = {
                user_msg_count : userMessageCount,
                pandit_msg_count: panditMessageCount,
                session_id: this.session_id, 
                end_by: end_by,
            }
            
            await axios.post('/api/user/chat-complete', data, this.config).then((res) => {
                if(res.data.status == 'success') {
                    // toast.fire({'icon': 'success', 'title': 'Chat Ended!'}) ;
                    clearInterval(this.timerInterval)
                    document.getElementById("timer").textContent = "";
                    const sessionDoc = doc(this.panditDocRef, "sessions", this.panditSessionRef.id);
                    updateDoc(sessionDoc, { status: 'completed', end_time: res.data.end_time, end_by: end_by })
                    this.current_session = null
                    this.endTime = null
                    this.start_time = null
                    this.expectedTimeInMinutes = null
                    this.remainingTimeInSeconds = null
                    this.panditDocRef = null
                    this.panditSessionRef = null
                    localStorage.removeItem('current_session')
                    localStorage.removeItem('timeJSON')
                    this.msgsSnapshot()
                    this.emitter.emit('get_user')
                    
                    if(type == 'invitation_expired')
                        Swal.fire({
                            title: "Chat Invitation Expired!",
                            text: "The invitation has expired. Please try sending a new one.",
                            icon: 'info',
                            confirmButtonText: "OK",
                            confirmButtonColor: "#3085d6",
                        });
                    else
                        Swal.fire({
                            title: "Completed!",
                            text: "Your chat has been ended.",
                            icon: "success",
                        })
                }
            });
        },
        async chat_scroll(e) {
            if($(e.target).scrollTop()==0) {
                this.msg_loading = true;
                var docRef = doc(db, "chats", this.current_chat_id);
                var q = query(collection(docRef, 'messages'), orderBy("messageTimestamp","desc"), startAfter(this.last_limit), limit(100))
                var docSnap =  await getDocs(q);
                if(docSnap.docs.length > 0) {
                    docSnap.forEach((data) => {
                        this.chat_reverse.unshift(data.data());
                    })
                    this.last_limit = docSnap.docs[docSnap.docs.length-1];
                }
                this.msg_loading = false;
            }
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if(file.size > 1024*1024) {
                toast.fire({
                    title: "Please Check!",
                    text: "File size must be less than 1 MB.",
                    icon: "info",
                })
                return ;
            }

            this.uploading = true
            
            const storageRef = ref(fireStorage, '/chats/'+this.current_chat_id+'/'+ file.name);

            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(ref(storageRef)).then(downloadURL => {
                    const colRef = collection(this.docRef, 'messages')
                    var str  = Date.now().toString() ;
                    setDoc(doc(colRef, str), {
                        "senderId" : this.user.id,
                        "message" : downloadURL,
                        "messageType" : 'file',
                        "messageTimestamp" : str,
                        "sessionId" : this.session_id,
                        "fileType" : file.type,
                        "fileName" : file.name,
                        "fileSize" : file.size,
                        "seen" : 0,
                    })
                    updateDoc(this.docRef, {
                        "recentMsgBy" : this.user.id,
                        "recentMsg" : downloadURL,
                        "recentMsgType" : 'file',
                        "recentMsgTimestamp" : str,
                    })
                    $('body').find('.scroll_container').animate({ scrollTop: $('body').find('.scroll_container')[0].scrollHeight }, 400);
                    this.uploading = false
                });
            });
            
        },
        speech() {
            var SpeechRecognition = SpeechRecognition || webkitSpeechRecognition;
            if (SpeechRecognition !== undefined && SpeechRecognition !== null) {
                var recognition = new SpeechRecognition();
                recognition.lang = 'en-IN' ;

                recognition.onspeechstart = function () {
                    this.listening = true;
                };
                if (this.listening === false) {
                    recognition.start();
                }
                recognition.onerror = function (event) {
                    this.listening = false;
                };
                recognition.onresult = function (event) {
                    this.text = event.results[0][0].transcript ;
                };
                recognition.onspeechend = function (event) {
                    this.listening = false;
                    recognition.stop();
                };
            }
        },
    },
};
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s ease-in;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
    filter: blur(2px);
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.2s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}
</style>