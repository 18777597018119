<template>
    <div class="main">
        <section class="section orange">
            <div class="container">
                <div class="row">
                    <div class="col-10 offset-1 col-lg-12 offset-lg-0">
                        <p class="title">Discover the magic of celestial celebrations! Explore festival dates from around the world and align your plans with the stars. Embrace tradition, culture, and cosmic connections as you navigate through our comprehensive calendar. Let the stars guide your festivities!</p>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <form class="row" @submit.prevent="submit">
                    <div class="col-12 col-lg-4">
                        <!-- sticky -->
                        <div class="card outline p-lg-4" data-top="5%">
                            <nav class="navbar navbar-expand-lg navbar-light">
                                <div class="container-fluid" :class="{'d-block': !mobile}">
                                    <div class="flex-bc w-100 border-bottom pb-2 gap-2">
                                        <h6>Filter</h6>
                                        <b class="cursor-pointer ms-auto" :class="showAll ? 'text-orange' : 'text-dark'" @click="all_click()">{{ showAll ? 'Go Back' : 'Show All' }}</b>
                                        <button class="btn small navbar-toggler" type="button" @click="showFilter=!showFilter">
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                    </div>
                                    <transition name="slide-down">
                                        <div class="collapse navbar-collapse flex-column pt-3 d-block" v-if="showFilter">
                                            <transition name="fade" mode="out-in">
                                                <div class="form-group input-group orange py-3" v-if="type=='string'">
                                                    <label class="w-100 flex-bc mb-2" @click="type='date'">
                                                        <span class="cursor-pointer text-orange">Search By Date Instead ?</span> 
                                                    </label>
                                                    <input type="search" autofocus required class="form-control" v-model="search" @keyup.enter="submit" placeholder="Festival Name">
                                                    <div class="input-group-append">
                                                        <button type="submit" class="btn orange-dark"><i class="fas fa-search mx-2 fa-2x"></i></button>
                                                    </div>
                                                </div>
                                                <div class="form-group orange py-3" v-else>
                                                    <label class="w-100 flex-bc mb-2" @click="type='string'">
                                                        <span class="cursor-pointer text-orange">Search By Name Instead ?</span> 
                                                    </label>
                                                    <div class="flex-cc gap-2 mb-2">
                                                        <VueDatePicker hide-offset-dates model-type="yyyy-MM-dd" :min-date="min_date" :max-date="max_date" :year-range="[2000, 2050]" prevent-min-max-navigation v-model="from" format="dd MMM, yy" :month-change-on-scroll="false" :enable-time-picker="false" :clearable="false"></VueDatePicker>
                                                        <VueDatePicker hide-offset-dates model-type="yyyy-MM-dd" :min-date="min_date" :max-date="max_date" :year-range="[2000, 2050]" prevent-min-max-navigation v-model="to" format="dd MMM, yy" :month-change-on-scroll="false" :enable-time-picker="false" :clearable="false"></VueDatePicker>
                                                    </div>
                                                    <button type="button" @click="submit()" class="btn dark ms-auto">Apply</button>
                                                </div>
                                            </transition>
                                            <h6 class="w-100">
                                                Panchangs
                                                <small class="float-end cursor-pointer" v-if="panchang.length" @click="panchang=[];submit();"><i>Clear</i></small>
                                                <hr>
                                            </h6>
                                            <div class="w-100 form-group">
                                                <v-select :options="panchangs" v-model="panchang" :reduce="i => i.crypt" @option:selected="submit" multiple :placeholder="'Select one to filter'" class="small-height h-auto bg-white" :clearable="false"></v-select>
                                            </div>
                                            <h6 class="w-100 mt-4">
                                                Categories
                                                <small class="float-end cursor-pointer" v-if="category.length" @click="category=[];submit();"><i>Clear</i></small><hr>
                                            </h6>
                                            <div class="w-100 form-group">
                                                <v-select :options="categories" v-model="category" :reduce="i => i.crypt" @option:selected="submit" multiple :placeholder="'Select one to filter'" class="small-height h-auto bg-white" :clearable="false"></v-select>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div class="col-10 offset-1 col-lg-6">
                        <!-- <transition-group name="fade" appear :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave"> -->
                        <transition-group name="fade" appear>
                            <div class="festive-date" v-for="(i,index) in festivals" :key="index" :data-index="index">
                                <div class="wrapper" :class="{'no-image' : i.image ? false: true}">
                                    <img :src="i.image || '/app-assets/images/h-logo.png'" :alt="i.title">
                                </div>
                                <div class="content">
                                    <div class="title">{{ i.title }}</div>
                                    <div class="panchang">Panchang: <b>{{ i.panchang_label }}</b></div>
                                    <div class="timings" v-if="i.start != i.end">
                                        Dates: <b>{{ $filters.date(i.start)+'-'+$filters.date(i.end) }}</b>
                                    </div>
                                    <div class="date" v-else>
                                        Date: <b>{{ $filters.date(i.start) }}</b>
                                    </div>
                                    <div class="date" v-if="i.cat_labels.length">
                                        Categories: <b>{{ i.cat_labels.join(', ') }}</b>
                                    </div>
                                    <div class="description">{{ i.description }}</div>
                                </div>
                            </div>
                            <nav key="Page navigation" aria-label="Page navigation" v-if="festivals.length && showAll">
                                <ul class="custom-pagination" v-if="pagination?.pageCount > 7">
                                    <li class="page-item prev-item" @click="pageNo = (pageNo > 1) ? pageNo - 1 : pagination?.pageCount"><i class="fas fa-sm fa-long-arrow-left"></i></li>
                                    <li class="page-item" :class="{ 'active' : pageNo==1 }" @click="pageNo=1"><a class="page-link" href="#">1</a></li>
                                <li class="divider" :class="{ 'active' : pageNo > 1 && pageNo < pagination?.pageCount }"></li>
                                    <li class="page-item" :class="{ 'active' : pageNo==pagination?.pageCount }" @click="pageNo = pagination?.pageCount"><a class="page-link" href="#">{{ pagination?.pageCount }}</a></li>
                                    <li class="page-item next-item" @click="pageNo = pageNo < pagination?.pageCount ? pageNo+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                </ul>
                                <ul class="custom-pagination" v-else>
                                    <li class="page-item prev-item" @click="pageNo = (pageNo > 1) ? pageNo - 1 : pagination?.pageCount"><i class="fas fa-long-arrow-left"></i></li>
                                    <li class="page-item" :class="{'active' : pageNo == i}" v-for="i in pagination?.pageCount" @click="pageNo=i" :key="i"><a class="page-link" href="#">{{ i }}</a></li>
                                    <li class="page-item next-item" @click="pageNo = pageNo < pagination?.pageCount ? pageNo+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                </ul>
                            </nav>
                        </transition-group>
                        <transition name="fade">
                            <loader v-if="loading && !err" />
                            <div class="card" v-else-if="!loading && err">
                                <div class="text-center row match-height">
                                    <div class="col-lg-6 spin-mandala ty">
                                        <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                        <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                    </div>
                                    <div class="col-lg-6" v-if="!mobile">
                                        <img src="/app-assets/images/avatar.png" alt="Mandala">
                                    </div>
                                </div>
                                <p class="desc">Oops, {{ err }}</p>
                            </div>
                        </transition>
                    </div>
                </form>
            </div>
        </section>
    </div>
</template>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s linear;
}
.fade-enter-from, .fade-leave-to {
    filter: blur(5px) ;
    opacity: 0 ;
}
.flip-enter-active, .flip-leave-active {
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.flip-enter-from {
    transform: translateY(100%);
    opacity: 0;
    filter: blur(2px);
    
}
.flip-leave-to {
    transform: translateY(-100%);
    opacity: 0;
    filter: blur(2px);
}
</style>
<script>
export default {
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            search: '',
            from: moment().format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
            min_date: moment('2000', 'YYYY').startOf('year').format('YYYY-MM-DD'),
            max_date: moment('2050', 'YYYY').endOf('year').format('YYYY-MM-DD'),
            panchang: [],
            festivals: [],
            showFilter: !this.isMobile(),
            pagination: null,
            panchangs: [],
            categories: [],
            category: [],
            errors: [],
            pageNo: 1,
            type: 'date',
            showAll: false,
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            loading: false,
            err: false,
       };
    },
    watch: {
        pageNo(to, from) {
            this.show_all();
        }
    },
    async created(){
        await this.index()
        await this.submit()
    },
    methods: {
        onBeforeEnter(el) {
            el.style.opacity = 0
        },
        onEnter(el, done) {
            gsap.to(el, {
                opacity: 1,
                delay: el.dataset.index * 0.15,
                onComplete: done
            })
        },
        onLeave(el, done) {
            gsap.to(el, {
                opacity: 0,
                delay: el.dataset.index * 0.15,
                onComplete: done
            })
        },
        async index() {
            await axios.get('/api/user/panchangs/get', this.config).then(async (res) => {
                this.panchangs = res.data.panchangs
                this.categories = res.data.categories
                // this.panchangs.unshift({'label': 'All', 'crypt': 'All'})
                this.emitter.emit('scroll_section')
            })
        },
        async submit() {
            if(this.type == 'string') if(!this.search) return ;
            else if(this.type == 'date') if(!this.from || !this.to) return ;

            this.err = false
            this.showAll = false
            this.loading = true
            var data = {
                type: this.type,
                panchang: this.panchang,
                category: this.category,
            }
            if(this.type == 'string') data.search = this.search
            else if(this.type == 'date') data.search = [this.from,this.to]

            this.festivals = []
            await axios.post('/api/user/festive-dates/get', data, this.config).then(async (res) => {
                this.loading = false
                this.festivals = res.data.records
                this.emitter.emit('scroll_section')
                if(!res.data.records.length)
                this.err = 'No Records Found.'
            }).catch((err) => {
                this.loading = false
                this.err = 'Server Error Occured!'
            })
        },
        all_click() {
            if(this.showAll) {
                this.showAll = false ; this.submit() ;
                this.showFilter = true ;
            }
            else {
                this.show_all() ;
                this.showFilter = false ;
            }
        },
        show_all() {
            this.err = false
            this.loading = true
            this.loader_text = 'Loading . . .'
            this.festivals = []
            axios.get('/api/user/festive-dates/get-all?page='+this.pageNo, this.config).then(async (res) => {
                this.loading = false
                this.showAll = true
                this.festivals = Object.values(res.data.records.data)
                this.emitter.emit('scroll_section')
                if(Object.values(res.data.records.data).length) {
                    this.loading = false
                    this.pagination = {
                        "page": res.data.records.current_page,
                        "pageSize": res.data.records.per_page,
                        "pageCount": res.data.records.last_page,
                        "total": res.data.records.total
                    }
                }
                if(!res.data.records.data.length)
                this.err = 'No Records Found.'
            }).catch((err) => {
                this.loading = false
                this.err = 'Server Error Occured!'
            })
        },
    }
}
</script>