
//Components
import Index from './components/user/pages/index.vue';
import Transaction from './components/user/pages/transaction.vue';
import Astrologers from './components/user/pages/astrologers.vue';
import AstrologerDetails from './components/user/pages/astrologer-details.vue';
import Chats from './components/user/pages/chats.vue';
import Kundli from './components/user/pages/kundli.vue';
import KundliResult from './components/user/pages/kundli-result.vue';
import MatchmakingResult from './components/user/pages/matchmaking-result.vue';
import MatchMaking from './components/user/pages/matchmaking.vue';
import OurServices from './components/user/pages/our-services.vue';
import Wallet from './components/user/pages/wallet.vue';
import EPujas from './components/user/pages/astro-pujas.vue';
import PujaBooking from './components/user/pages/puja-booking.vue';
import MyBookings from './components/user/pages/my-bookings.vue';
import BookingDetail from './components/user/pages/booking-detail.vue';
import PujaReview from './components/user/pages/puja-review.vue';
import Pujas from './components/user/pages/pujas.vue';
import PujaDetail from './components/user/pages/puja-detail.vue';
import Blogs from './components/user/pages/blogs.vue';
import BlogDetail from './components/user/pages/blog-detail.vue';
import ZodiacMatch from './components/user/pages/zodiac-match.vue';
import ArdhPahar from './components/user/pages/ardh-pahar.vue';
import FestiveDate from './components/user/pages/festive-date.vue';
import Numerology from './components/user/pages/numerology.vue';
import NumerologyResult from './components/user/pages/numerology-result.vue';
import RudrakshGemstone from './components/user/pages/rudraksh-gemstone.vue';
import Panchang from './components/user/pages/panchang.vue';
import RudrakshGemstoneResult from './components/user/pages/rudraksh-gemstone-result.vue';
import MyProfile from './components/user/pages/my-profile.vue';
import ScholarRegistration from './components/user/pages/scholarRegistration.vue';
import BecomeAScholar from './components/user/pages/becomeAScholar.vue';
import ScholarSkillTest from './components/user/pages/scholarSkillTest.vue';
import Horoscope from './components/user/pages/horoscope.vue';
import LiveStream from './components/user/pages/live-stream.vue';
import PolicyPage from './components/user/pages/policy-page.vue';

var app_name = import.meta.env.VITE_APP_NAME
var app_name = 'Astro'

const routes = [
    {
        "path": "/", "name": "Index", "component": Index, "meta": {
            "title": false, "auth": false, "both": true
        }
    }, {
        "path": "/chats/:id?", "name": "Chats", "component": Chats, "meta": {
            "title": "Astro Chat", "auth": true
        }
    }, {
        "path": "/our-services", "name": "OurServices", "component": OurServices, "meta": {
            "title": "Our Services", "auth": false, "both": true
        }
    }, {
        "path": "/kundli", "name": "Kundli", "component": Kundli, "meta": {
            "title": app_name+" Kundli", "auth": false, "both": true
        }
    }, {
        "path": "/kundli/:id/result", "name": "KundliResult", "component": KundliResult, "meta": {
            "title": app_name+" Kundli Result", "auth": true
        }
    }, {
        "path": "/kundli-matchmaking", "name": "MatchMaking", "component": MatchMaking, "meta": {
            "title": app_name+" Matchmaking", "auth": false, "both": true
        }
    }, {
        "path": "/kundli-matchmaking/:id/result", "name": "MatchmakingResult", "component": MatchmakingResult, "meta": {
            "title": app_name+" Matchmaking Result", "auth": true
        }
    }, {
        "path": "/astrologers", "name": "Astrologers", "component": Astrologers, "meta": {
            "title": "Astrologers", "auth": false, "both": true
        }
    }, {
        "path": "/astrologer/:username", "name": "AstrologerDetails", "component": AstrologerDetails, "meta": {
            "title": "Astrologer Details", "name": "Astrologers", "link": "/astrologers", "auth": false, "both": true
        }
    }, {
        "path": "/shubh-kal", "name": "ArdhPahar", "component": ArdhPahar, "meta": {
            "title": "Shubh Kal", "auth": false, "both": true
        }
    }, {
        "path": "/zodiac-match", "name": "ZodiacMatch", "component": ZodiacMatch, "meta": {
            "title": "Zodiac Compatibility", "auth": false, "both": true
        }
    }, {
        "path": "/festive-dates", "name": "FestiveDate", "component": FestiveDate, "meta": {
            "title": "Shubh Din", "auth": false, "both": true
        }
    }, {
        "path": "/astro-mall", "name": "AstroMall", "component": Index, "meta": {
            "title": "Astro Mall", "auth": false, "both": true
        }
    }, {
        "path": "/pujas", "name": "Pujas", "component": Pujas, "meta": {
            "title": "Pujas & Mantras", "auth": false, "both": true
        }
    }, {
        "path": "/puja/:slug", "name": "PujaDetail", "component": PujaDetail, "meta": {
            "title": "Puja Details", "name": "Pujas & Mantras", "link": "/pujas", "auth": false, "both": true
    }
    }, {
        "path": "/blogs", "name": "Blogs", "component": Blogs, "meta": {
            "title": app_name+" Blogs", "auth": false, "both": true
        }
    }, {
        "path": "/blog/:slug", "name": "BlogDetail", "component": BlogDetail, "meta": {
            "title": "Blog Details", "name": "Blogs", "link": "/blogs", "auth": false, "both": true
        }
    }, {
        "path": "/horoscope/:sign?", "name": "Horoscope", "component": Horoscope, "meta": {
            "title": "Horoscope", "auth": false, "both": true
        }
    }, {
        "path": "/numerology", "name": "Numerology", "component": Numerology, "meta": {
            "title": app_name+" Numerology", "auth": false, "both": true
        }
    }, {
        "path": "/numerology/:id/result", "name": "NumerologyResult", "component": NumerologyResult, "meta": {
            "title": app_name+" Numerology Result", "auth": true
        }
    }, {
        "path": "/rudraksh-gemstone", "name": "RudrakshGemstone", "component": RudrakshGemstone, "meta": {
            "title": "Rudraksh Gemstone Suggestion", "auth": false, "both": true
        }
    }, {
        "path": "/rudraksh-gemstone/:id/result", "name": "RudrakshGemstoneResult", "component": RudrakshGemstoneResult, "meta": {
            "title": "Rudraksh Gemstone Suggestion Result", "auth": true
        }
    }, {
        "path": "/astro-pujas", "name": "EPujas", "component": EPujas, "meta": {
            "title": false, "auth": false, "both": true
        }
    }, {
        "path": "/puja-booking/:slug", "name": "MyBookings", "component": PujaBooking, "meta": {
            "title": "Puja Booking", "auth": false, "both": true
        }
    }, {
        "path": "/my-bookings", "name": "PujaBooking", "component": MyBookings, "meta": {
            "title": "My Bookings", "auth": true
        }
    }, {
        "path": "/booking-detail/:id", "name": "BookingDetail", "component": BookingDetail, "meta": {
            "title": "Booking Details", "name": "My Bookings", "link": "/my-bookings", "auth": true
        }
    }, {
        "path": "/puja-review/:id", "name": "PujaReview", "component": PujaReview, "meta": {
            "title": "Puja Review", "auth": true
        }
    }, {
        "path": "/panchang", "name": "Panchang", "component": Panchang, "meta": {
            "title": app_name+" Panchang", "auth": false, "both": true
        }
    }, {
        "path": "/my-profile", "name": "MyProfile", "component": MyProfile, "meta": {
            "title": "My Profile", "auth": true
        }
    }, {
        "path": "/notifications", "name": "notifications", "component": Index, "meta": {
            "title": "Astro Notifications", "auth": true
        }
    }, {
        "path": "/astro-wallet", "name": "Wallet", "component": Wallet, "meta": {
            "title": "Astro Wallet", "auth": true
        }
    }, {
        "path": "/transaction/:id", "name": "Transaction", "component": Transaction, "meta": {
            "title": "Transaction Details", "name": "Transactions", "link": "/astro-wallet", "auth": true
    }
    }, {
        "path": "/live-stream/:call_id", "name": "LiveStream", "component": LiveStream, "meta": {
            "title": "Live Stream", "auth": false, "both": true
        }
    }, {
        "path": "/scholar-registration", "name": "ScholarRegistration", "component": ScholarRegistration, "meta": {
            "title": "Scholar Registrations", "auth": false, "both": true
        }
    }, {
        "path": "/become-a-scholar", "name": "becomeAScholar", "component": BecomeAScholar, "meta": {
            "title": false, "auth": false, "both": true
        }
    }, {
        "path": "/scholar-skill-test", "name": "scholarSkillTest", "component": ScholarSkillTest, "meta": {
            "title": "Scholar Skill Exam", "auth": false, "both": true
        }
    }, {
        "path": "/policy-page/:slug", "name": "policyPage", "component": PolicyPage, "meta": {
            "title": "Policy Page", "name": "Policy Page", "link": "#", "auth": false, "both": true
        }
    }, { "path": "/:pathMatch(.*)*", "redirect": "/" }
]

export default routes