<template>
    <div class="main">
        <section class="section orange pb-0" effect="fade">
            <div class="container">
                <swiper
                    :class="'hero-swiper'"
                    :keyboard="{ enabled: true }"
                    :pagination="{ dynamicBullets: true, clickable: true }"
                    :autoplay="{ delay: 5000 }"
                    :freeMode="true"
                    :slides-per-view="1"
                    :navigation="false"
                    :loop="false"
                    :speed="800"
                    :modules="modules"
                    >
                    <!-- :effect="'fade'" -->
                    <swiper-slide>
                        <div class="hero-banner yellow" :class="{'h-100': mobile}">
                            <div class="row" :class="{'h-100': mobile}">
                                <div class="col-lg-7" :class="{'d-flex flex-column justify-content-around': mobile}">
                                    <div class="hero-title">We welcome you to {{ $appname }}</div>
                                    <div class="hero-subtitle">Explore a platform dedicated to providing expert future insights, connecting you with professional astrologers, and offering a comprehensive suite of astrological services to guide you through life's journey with confidence.</div>
                                    <router-link to="/astrologers" type="button" class="btn orange-light d-inline">Start Chat Now</router-link>
                                </div>
                                <div class="col-lg-4 offset-lg-1 spin-mandala" v-if="!mobile">
                                    <img src="/app-assets/images/mandala.png" alt="Login Mandala" class="img-fluid mandala">
                                    <img src="/app-assets/images/avatar.png" alt="Login Avatar" class="img-fluid avatar">
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="hero-banner yellow">
                            <div class="row">
                                <div class="col-lg-7">
                                    <div class="hero-title">Join {{ $appname }} as Astrologer</div>
                                    <div class="hero-subtitle">Join us as an astrologer and share your astrological insights with a vibrant community to enhance your studies, connect with fellow scholars.</div>
                                    <router-link to="/become-a-scholar" type="button" class="btn orange-light d-inline">Join Here</router-link>
                                </div>
                                <div class="col-lg-4 offset-lg-1 spin-mandala" v-if="!mobile">
                                    <img src="/app-assets/images/mandala.png" alt="Login Mandala" class="img-fluid mandala">
                                    <img src="/app-assets/images/avatar.png" alt="Login Avatar" class="img-fluid avatar">
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide v-for="b,i in filtered_banners" :key="i">
                        <img @click="b.link ? $router.push(b.link) : ''" :src="b.image" class="hero-image" alt="Banner Images">
                    </swiper-slide>
                </swiper>
            </div>
        </section>
        <section class="section orange pb-4">
            <!-- <div class="animated-background"></div> -->
            <div class="container">
            <!-- moving-border -->
            <h4 class="section-title flex-bc moving-border">
                <span>Our Services</span>
                <router-link to="/our-services" class="text-dark">View All</router-link>
            </h4>
                <swiper
                    :class="'blogs-swiper'"
                    :keyboard="{ enabled: true }"
                    :pagination="{ dynamicBullets: true, clickable: true }"
                    :autoplay="true"
                    :freeMode="true"
                    :breakpoints="service_breakpoints"
                    :navigation="false"
                    :loop="false"
                    :speed="500"
                    :grid="{ rows: 1 }"
                    :modules="modules"
                >
                    <swiper-slide>
                        <router-link to="/horoscope" class="service-box">
                            <div class="content">
                                <div class="wrapper">
                                    <img src="/app-assets/images/logo_horoscope.png" class="img-fluid" alt="Service Logo">
                                </div>
                                <div class="title">Today's Horoscope</div>
                                <div class="desc">Signs of Mangal Dosh, also known as Manglik Dosha, are believed to include certain astrological conditions where the planet Mars (Mangal) has a malefic influence. </div>
                            </div>
                        </router-link>
                    </swiper-slide>
                    <swiper-slide>
                        <router-link to="/kundli" class="service-box">
                            <div class="content">
                                <div class="wrapper">
                                    <img src="/app-assets/images/logo_kundli.png" class="img-fluid" alt="Service Logo">
                                </div>
                                <div class="title">Free Kundli</div>
                                <div class="desc">Signs of Mangal Dosh, also known as Manglik Dosha, are believed to include certain astrological conditions where the planet Mars (Mangal) has a malefic influence. </div>
                            </div>
                        </router-link>
                    </swiper-slide>
                    <!-- <swiper-slide>
                        <router-link to="/experts" class="service-box">
                            <div class="wrapper">
                                <img src="/app-assets/images/logo_horoscope.png" class="img-fluid" alt="Service Logo">
                            </div>
                            <div class="title">Chat/Talk with Expert</div>
                            <div class="desc">Signs of Mangal Dosh, also known as Manglik Dosha, are believed to include certain astrological conditions where the planet Mars (Mangal) has a malefic influence. </div>
                        </router-link>
                    </swiper-slide> -->
                    <swiper-slide>
                        <a target="_blank" :href="$mall_domain" class="service-box">
                            <div class="content">
                                <div class="wrapper">
                                    <img src="/app-assets/images/logo_blogs.png" class="img-fluid" alt="Service Logo">
                                </div>
                                <div class="title">ShopForU</div>
                                <div class="desc">Signs of Mangal Dosh, also known as Manglik Dosha, are believed to include certain astrological conditions where the planet Mars (Mangal) has a malefic influence. </div>
                            </div>
                        </a>
                    </swiper-slide>
                    <swiper-slide>
                        <router-link to="/astro-pujas" class="service-box">
                            <div class="content">
                                <div class="wrapper">
                                    <img src="/app-assets/images/logo_blogs.png" class="img-fluid" alt="Service Logo">
                                </div>
                                <div class="title">Puja Booking</div>
                                <div class="desc">Signs of Mangal Dosh, also known as Manglik Dosha, are believed to include certain astrological conditions where the planet Mars (Mangal) has a malefic influence. </div>
                            </div>
                        </router-link>
                    </swiper-slide>
                </swiper>
            </div>
        </section>
        <horoscope-section class="pt-4"></horoscope-section>
        <live-stream-section class="pt-4"></live-stream-section>
    </div>
</template>
<script setup>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { EffectFade, Autoplay, Pagination, Keyboard, Navigation } from 'swiper';
    import 'swiper/css/effect-fade';
</script>
<script>
export default {
    props: ['user', 'banners'],
    data() {
        return {
            mobile: this.isMobile(),
            modules: [Autoplay, EffectFade, Pagination, Keyboard, Navigation ],
            service_breakpoints: {
                '0': {
                    slidesPerView: 1,
                },
                '768': {
                    slidesPerView: 2,
                },
                '1024': {
                    slidesPerView: 4,
                },
            },
        };
    },
    created() {
        this.emitter.emit('talk-section-key', 'index')
    },
    computed: {
        filtered_banners() {
            if(this.mobile) {
                return this.banners.filter(i => i.type == 'mobile')
            }
            else {
                return this.banners.filter(i => i.type == 'web')
            }
        }
        // this.emitter.emit('animate_background')
        // this.emitter.emit('blog_section', true)
    },
    // beforeUnmount() {
    //     this.emitter.emit('blog_section', false)
    // },
}
</script>