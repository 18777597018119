<template>
    <div class="puja-card">
        <router-link :to="'/'+this.type+'/'+puja?.slug" class="img-wrapper">
            <img @error="setAltImg" v-if="puja?.banner" :src="puja?.banner" :alt="'Banner - '+ puja?.title">
            <img v-else src="/app-assets/images/logo.png" alt="Banner Logo">
            <div class="category">{{ puja?.tags?.splice(0,3).join(',') }}</div>
        </router-link>
        <div class="content">
            <router-link :to="'/'+this.type+'/'+puja?.slug" class="puja-title">
                {{ puja?.title }}
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    props: ['puja', 'type'],
    methods: {
        setAltImg(event) { 
            event.target.src = "/app-assets/images/logo.png" 
        } 
    },
}
</script>