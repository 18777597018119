<template>
    <div class="main">
        <section class="section orange pt-4" ref="section">
            <!-- <div v-if="!loading" class="animated-background"></div> -->
            <div class="container">
                <div class="row">
                    <transition name="grow" mode="out-in">
                        <div class="col-lg-8 offset-lg-2" v-if="loading">
                            <loader :text="'Loading. . .'" />
                        </div>
                        <div v-else-if="!loading && err" class="col-12 col-lg-8 offset-lg-2">
                            <div class="card">
                                <div class="text-center row match-height">
                                    <div class="col-lg-6 spin-mandala ty">
                                        <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                        <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                    </div>
                                    <div class="col-lg-6">
                                        <img src="/app-assets/images/avatar.png" alt="Mandala">
                                    </div>
                                </div>
                                <p class="desc">
                                    Oops, {{ err }}.<br><br>Please try again later.
                                </p>
                                <div class="flex-cc">
                                    <router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-10 offset-lg-1" v-else-if="!loading && !err && result">
                            <button type="button" class="btn orange-dark large text-uppercase mx-auto mb-5">Kundli Matching Report</button>
                            <div class="row">
                                <div class="col-lg-6">
                                    <table class="table kundli-table mb-5">
                                        <thead>
                                            <tr>
                                                <th colspan="2">
                                                    <div class="flex-bc">
                                                        <h6>Basic Details</h6>
                                                        <button type="button" class="btn yellow small">Boy</button>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><b>NAME</b></td>
                                                <td>{{ data.boy_name }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>DOB</b></td>
                                                <td>{{ data.boy_dob }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>TOB</b></td>
                                                <td>{{ data.boy_tob }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>BIRTHPLACE</b></td>
                                                <td>{{ data.boy_city }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>JANAM RASHI</b></td>
                                                <td class="text-capitalize">{{ data.type == 1 ? result.bhakoot.boy_rasi_name : result.rasi.boy_rasi }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-lg-6">
                                    <table class="table kundli-table mb-5">
                                        <thead>
                                            <tr>
                                                <th colspan="2">
                                                    <div class="flex-bc">
                                                        <h6>Basic Details</h6>
                                                        <button type="button" class="btn yellow small">Girl</button>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><b>NAME</b></td>
                                                <td>{{ data.girl_name }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>DOB</b></td>
                                                <td>{{ data.girl_dob }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>TOB</b></td>
                                                <td>{{ data.girl_tob }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>BIRTHPLACE</b></td>
                                                <td>{{ data.girl_city }}</td>
                                            </tr>
                                            <tr>
                                                <td><b>JANAM RASHI</b></td>
                                                <td class="text-capitalize">{{ data.type == 1 ? result.bhakoot.girl_rasi_name : result.rasi.girl_rasi }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-12">
                                    <button type="button" class="btn orange-dark text-uppercase mx-auto mb-5">
                                        <b>{{ result.bot_response }}</b>
                                    </button>
                                    <div class="table-responsive">
                                        <table class="table kundli-table mb-5">
                                            <thead class="text-nowrap">
                                                <tr>
                                                    <th>Attribute</th>
                                                    <th>Boy</th>
                                                    <th>Girl</th>
                                                    <th>Score</th>
                                                    <th>Out of</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody class="text-capitalize">
                                                <tr v-for="(a,index) in array_fields" :key="index">
                                                    <td>{{ a }}</td>
                                                    <td :class="{'font-weight-bold text-lg': this.data.lang != 'en'}">{{ this.result[a]['boy_'+key_text(a)] }}</td>
                                                    <td :class="{'font-weight-bold text-lg': this.data.lang != 'en'}">{{ this.result[a]['girl_'+key_text(a)] }}</td>
                                                    <td>{{ this.result[a][a] }}</td>
                                                    <td>{{ this.result[a]['full_score'] }}</td>
                                                    <td :class="{'font-weight-bold text-lg': this.data.lang != 'en'}">{{ this.result[a]['description'] }}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" class="text-end"><h6>Total</h6></td>
                                                    <td><h6>{{ this.result.score }}</h6></td>
                                                    <td><h6>{{ this.data.type == 1 ? 36 : 10 }}</h6></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-lg-8 offset-lg-2">                                    
                                    <div class="card outline">
                                        <h4 class="text-orange text-center">Unlock Your Full Matchmaking Report!</h4>
                                        <h6 class="text-dark text-center my-4">Get the Extended Version now available<br>in PDF format.</h6>
                                        <button type="button" @click="create_pdf" :disabled="disBtn" class="btn orange-dark mx-auto mb-4">
                                            <i v-if="disBtn" class="fas fa-spinner fa-spin"></i>
                                            <transition name="flip" mode="out-in">
                                                <span v-if="!disBtn">Create PDF Now</span>
                                                <span v-else>Loading . . .</span>
                                            </transition>
                                        </button>
                                        <ul>
                                            <li style="list-style-type: disc;">Click Here to download the report</li>
                                            <li style="list-style-type: disc;">Separate Matchmaking images included</li>
                                            <li style="list-style-type: disc;">Easy, fast, and secure download</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    props: ['user','pricings'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            tab: 1,
            type: 'north',
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            id: '',
            result: [],
            data: [],
            loading: false,
            err: false,
            disBtn: false,
            showModal: false,
       };
    },
    created() {
        if(this.token) {
            this.id = this.$route.params.id
            this.index()
        } else this.loading = true
    },
    computed: {
        array_fields() {
            return Object.keys(this.result).filter(key => typeof this.result[key] === 'object' && this.result[key] !== null && !Array.isArray(this.result[key]));
        },
        pdf_price() {
            if(this.user.currency.code == 'INR')
                return this.pricings.matchmaking_pdf_price_INR
            else
                return this.pricings.matchmaking_pdf_price_USD
        },
    },
    methods: {
        key_text(key) {
            if(this.data.type == 1) {
                if(key == 'bhakoot') return 'rasi_name'
                else if(key == 'grahamaitri') return 'lord'
                else return key
            } else {
                switch (key) {
                    case 'dina': return 'star' ;
                    case 'mahendra': return 'star' ;
                    case 'sthree': return 'star' ;
                    case 'rasiathi': return 'lord' ;
                    case 'vasya': return 'rasi' ;
                    case 'vedha': return 'star' ;
                    default: return key;
                }
            }
        },
        index() {
            this.$Progress.start()
            this.loading = true
            axios.post('/api/user/get-matchmaking-result', {query: this.id}, this.config).then((res) => {
                this.loading = false
                if(res.data.status == 'success') {
                    this.data = res.data.data
                    this.result = res.data.result
                    this.$Progress.finish()
                } else {
                    this.err = res.data.message
                    this.$Progress.fail()
                }
                this.$nextTick(() => {
                    this.emitter.emit('scroll_section')
                    // this.emitter.emit('animate_background')
                })
            }).catch((err) => {
                this.$Progress.fail()
                toast.fire({'icon': 'error', 'title': 'Server Error Occured!'})
                this.loading = false
            })
        },
        async create_pdf() {
            if (this.pdf_price != 0) {
                const res = await Swal.fire({
                    icon: 'info',
                    title: 'Matchmaking PDF is a downloadable version.',
                    text: 'This Matchmaking pdf will cost you ' + this.user.currency.symbol + this.pdf_price,
                    confirmButtonText: 'Proceed',
                });
                if (res.isConfirmed) {
                    if (this.user.wallet_amount - this.pdf_price < 0) {
                        const res2 = await Swal.fire({
                            icon: 'error',
                            title: 'Low Wallet Balance.',
                            text: 'Please recharge your wallet to continue!',
                            confirmButtonText: 'Recharge',
                        });
                        if (!res2.isConfirmed) return;
                        else this.$router.push('/astro-wallet')
                    }
                } else return;
            }
            this.disBtn = true;
            this.$Progress.start();
            try {
                const res = await axios.post('/api/user/kundli-matchmaking-pdf-create', {id: this.id}, this.config);
                if (res.data.status == 'success') {
                    this.disBtn = false;
                    this.showModal = false
                    this.$Progress.finish();
                    Swal.fire({
                        icon: 'info',
                        title: 'PDF Generation in Progress',
                        text: 'This may take some time. You will be notified once it is ready.',
                    })
                    // window.open(res.data.pdf_url, '_blank').focus();
                    // toast.fire({ icon: 'success', title: 'PDF Generated!' });
                } else {
                    this.$Progress.fail();
                    this.disBtn = false;
                    toast.fire({ icon: 'error', title: res.data.message });
                }
            } catch(e) {
                this.disBtn = false;
                this.$Progress.fail();
                toast.fire({ icon: 'error', title: 'Error Occurred!' });
            }
        }
    }
}
</script>
<style scoped>
.flip-enter-active, .flip-leave-active {
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.flip-enter-from {
    transform: translateY(100%);
    opacity: 0;
    filter: blur(2px);
    
}
.flip-leave-to {
    transform: translateY(-100%);
    opacity: 0;
    filter: blur(2px);
}
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s ease-in;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
    filter: blur(2px);
}
.grow-enter-active, .grow-leave-active {
    transition: all 0.5s ease-out;
}
.grow-enter-from {
    opacity: 0;
    filter: blur(15px);
    transform: scale(0);
    transform-origin: center;
}
.grow-leave-to {
    opacity: 0;
    filter: blur(15px);
    transform: scale(0);
    transform-origin: center;
}
</style>