<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <span class="footer-head">{{ $appname }}</span>
                    <p>Looking for trusted astrological guidance? {{ $appname }} connects you with India's leading astrologers for accurate predictions and personal advice. Our expert astrologers provide detailed insights about your marriage, relationships, career, and health through traditional Kundli analysis. Get your answers through convenient options - phone consultations, live chat, or detailed reports. Experience personalized guidance that helps you navigate life's important decisions with confidence.</p>
                </div>
                <div class="col-lg-4">
                    <span class="list-head">Contact Us</span>
                    <p class="mt-2">We are available 24x7 on email support, click to send email.</p>
                    <div class="flex-sc gap-1">
                        <a :href="'mailto:support@'+$domain" class="btn yellow">
                            <i class="far fa-envelope"></i> support@{{$domain}}
                        </a>
                    </div>
                </div>
                <div class="col-lg-4">
                    <span class="list-head">Corporate Info</span>
                    <ul>
                        <li v-if="policies?.[0]?.is_active"><router-link :to="'/policy-page/'+policies?.[0]?.label">Terms & Conditions</router-link></li>
                        <li v-if="policies?.[1]?.is_active"><router-link :to="'/policy-page/'+policies?.[1]?.label">Privacy Policy</router-link></li>
                        <li v-if="policies?.[2]?.is_active"><router-link :to="'/policy-page/'+policies?.[2]?.label">Refund Policy</router-link></li>
                        <li v-if="policies?.[3]?.is_active"><router-link :to="'/policy-page/'+policies?.[3]?.label">Shipping Policy</router-link></li>
                        <li v-if="policies?.[4]?.is_active"><router-link :to="'/policy-page/'+policies?.[4]?.label">Disclaimer</router-link></li>
                        <li v-if="policies?.[5]?.is_active"><router-link :to="'/policy-page/'+policies?.[5]?.label">Cancellation Policy</router-link></li>
                    </ul>
                </div>
                <div class="col-lg-4">
                    <span class="list-head">Astrologers Links</span>
                    <ul>
                        <li><a target="_blank" :href="'https://pandit.'+$domain">Astrologer Login</a></li>
                        <li><router-link :to="'/become-a-scholar'">Astrologer Registration</router-link></li>
                    </ul>
                </div>
                <ul class="row features">
                    <li class="col-lg-4"><img src="/app-assets/images/f1.png" alt="Feature 1"><span>Money back gurantee</span></li>
                    <li class="col-lg-4"><img src="/app-assets/images/f2.png" alt="Feature 2"><span>Verified expert astrologers</span></li>
                    <li class="col-lg-4"><img src="/app-assets/images/f3.png" alt="Feature 3"><span>100% Secure Payments</span></li>
                </ul>
            </div>
        </div>
        <div class="copyright">
            <div class="container">
                <div class="flex-bc flex-column flex-md-row gap-3 text-center text-md-left">
                    <p>Copyright &copy; {{ year }} | Astro Bharat Pvt Ltd | All Rights Reserved</p>
                    <ul class="social-links">
                        <li><a href="https://www.facebook.com/people/AstroForu/61564449123267/" target="_blank" class="btn yellow"><i class="fab me-0 fa-facebook-f"></i></a></li>
                        <li><a href="https://www.instagram.com/astroforu9/" target="_blank" class="btn yellow"><i class="fab me-0 fa-instagram"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UClBBV-6HOmR_YikJ94dVgQQ" target="_blank" class="btn yellow"><i class="fab me-0 fa-youtube"></i></a></li>
                        <li><a href="https://x.com/AstroForU9" target="_blank" class="btn yellow"><i class="fab me-0 fa-x-twitter"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    props: ['policies'],
    data() {
        return {
            year: new Date().getFullYear(),
        };
    }
}
</script>