<template>
    <section class="section orange">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4 class="section-title flex-bc moving-border">
                        <span>Horoscope</span>
                    </h4>
                </div>
                <div class="col-12">
                    <swiper
                        :keyboard="false"
                        :pagination="false"
                        :autoplay="false"
                        :navigation="false"
                        :space-between="50"
                        :loop="false"
                        :speed="500"
                        :autoHeight="false"
                        :breakpoints="live_breakpoints"
                        :modules="modules"
                    >
                        <swiper-slide v-for="(h,i) in horoscopes" :key="i">
                            <router-link :to="'/horoscope/'+h.toLowerCase()" class="horoscope">
                                <div class="img-wrapper">
                                    <img :src="'/app-assets/images/zodiacs/'+(i+1)+'_'+h+'.png'" alt="">
                                </div>
                                <div class="name">{{ h }}</div>
                                <div class="date">{{ dates[i] }}</div>
                            </router-link>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Autoplay, Pagination, Keyboard, Navigation, Grid } from 'swiper';
</script>
<script>
export default {
    data() {
        return {
            horoscopes: ['Aries','Taurus','Gemini','Cancer','Leo','Virgo','Libra','Scorpio','Sagittarius','Capricorn','Aquarius','Pisces'],
            dates: ['Mar 21-Apr 19','Apr 20-May 20','May 21-Jun 21','Jun 22-Jul 22','Jul 23-Aug 22','Aug 23-Sep 22','sep 23-Oct 22','Oct 23-Nov 21','Nov 22-Dec 21','Dec 22-Jan 19','Jan 20-Feb 18','Feb 19-Mar 20'],
            modules: [Autoplay, Pagination, Keyboard, Navigation, Grid ],
            live_breakpoints: {
                '0': {
                    slidesPerView: 2,
                    grid: {fill: 'row',rows: 6},
                },
                '768': {
                    slidesPerView: 3,
                    grid: {fill: 'row',rows: 4},
                },
                '1024': {
                    slidesPerView: 4,
                    grid: {fill: 'row',rows: 3},
                },
                '1300': {
                    slidesPerView: 6,
                    grid: {fill: 'row',rows: 2},
                },
            },
        };
    }
}
</script>