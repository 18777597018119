<template>
    <div class="main">
        <section class="section orange" :class="token ? 'pt-5' : 'pt-5'">
            <!-- <div class="animated-background"></div> -->
            <div class="container">
                <form class="row" @submit.prevent="submit()">
                    <div class="col-12">
                        <div class="card">
                            <div class="row">
                                <div class="col-lg-3">
                                    <div class="form-group orange">
                                        <label class="mb-2">Date</label>
                                        <VueDatePicker hide-offset-dates :min-date="min_date" :max-date="max_date" utc prevent-min-max-navigation :year-range="[1920, 2030]" v-model="form.date" :clearable="false" :class="{'is-invalid': errors.date}" format="dd MMM, yyyy" :month-change-on-scroll="false" :enable-time-picker="false"></VueDatePicker>
                                        <span class="invalid-feedback" v-if="errors.date"><strong>{{ errors.date[0] }}</strong></span>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-4">
                                    <div class="form-group orange">
                                        <label class="mb-2">Time</label>
                                        <VueDatePicker v-model="form.time" :clearable="false" :class="{'is-invalid': errors.time}" model-type="HH:mm" format="h:mm a" time-picker></VueDatePicker>
                                        <span class="invalid-feedback" v-if="errors.time"><strong>{{ errors.time[0] }}</strong></span>
                                    </div>
                                </div> -->
                                <div class="col-lg-3">
                                    <div class="form-group orange">
                                        <label class="mb-2">Choose Language</label>
                                        <v-select class="w-100" :options="api_langs" :clearable="false" v-model="form.lang" :reduce="i => i.value"></v-select>
                                    </div>
                                </div>
                                <div class="col-lg-4 form-group orange">
                                    <label>Search Location</label>
                                    <GMapAutocomplete @placeholder="'Search Location'" @place_changed="getAddressData" :types="['geocode']" :fields="autocompleteFields" class="form-control" ></GMapAutocomplete>
                                    <!-- <input type="text" class="form-control" @paste.prevent required v-model="form.city" @input="debouncedSearch"> -->
                                </div>
                                <!-- <transition-group name="fade">
                                    <div key="loading" class="col-lg-2" v-if="city_search">
                                        <loader text="Loading. . ." :small="true" />
                                    </div>
                                    <div v-if="cities_array.length" key="dropdown" class="col-lg-6 form-group orange">
                                        <label>Select City</label>
                                        <v-select :options="cities_array" :clearable="false" @option:selected="putCity" v-model="form.pob" :reduce="i => i.label"></v-select>
                                    </div>
                                </transition-group> -->
                                <div class="col-lg-2 flex-sc">
                                    <button type="submit" :disabled="disBtn == 1" class="btn dark mt-3">
                                        <i v-if="disBtn == 1" class="fas fa-spinner fa-spin"></i>
                                        <transition name="flip" mode="out-in">
                                            <span v-if="!disBtn">Get Result</span>
                                            <span v-else>Loading . . .</span>
                                        </transition>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row match-height justify-content-center">
                    <transition name="flip" mode="out-in">
                        <div class="col-12" v-if="disBtn == 1">
                            <loader :text="'Loading. . .'" />
                        </div>
                        <div v-else-if="!disBtn && err" class="col-12 col-lg-8">
                            <div class="card">
                                <div class="text-center row match-height">
                                    <div class="col-lg-6 spin-mandala ty">
                                        <img src="/app-assets/images/mandala.png" alt="Mandala" class="mandala">
                                        <img src="/app-assets/images/Oops.png" alt="TY" class="avatar">
                                    </div>
                                    <div class="col-lg-6">
                                        <img src="/app-assets/images/avatar.png" alt="Mandala">
                                    </div>
                                </div>
                                <p class="desc">
                                    Oops, {{ err }}.<br><br>Please try again later.
                                </p>
                                <div class="flex-cc">
                                    <router-link :to="'/'" class="btn orange-dark">Take Me Home</router-link>
                                </div>
                            </div>
                        </div>
                    </transition>
                    <transition-group name="flip">
                        <div class="col-12 mt-4" v-if="result">
                            <div class="card outline">
                                <h3>Panchang Result <hr></h3>
                                <h5>It's a {{ result.day.name }}, rising from the {{ result.rasi.name }} sign</h5>
                                <div class="flex-sc gap-4 my-4" :class="{'flex-wrap':mobile}">
                                    <div class="flex-sc gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="48" viewBox="0 0 47 48" fill="none">
                                            <path d="M17.625 12.1914H20.5625V18.0664H26.4375V12.1914H29.375L23.5 3.37891L17.625 12.1914Z" fill="#FF7A4A"/>
                                            <path d="M36.4837 35.1627L38.775 29.8164L32.9 29.1408L32.3125 23.3539L26.9663 25.6452L23.5 21.0039L20.0338 25.6452L14.6875 23.3539L14.0119 29.2289L8.225 29.8164L10.5162 35.1627L5.875 38.6289H0V41.5664H47V38.6289H41.125L36.4837 35.1627ZM11.75 38.6289C11.8544 35.5493 13.1231 32.624 15.3 30.4432C17.4769 28.2625 20.4 26.9887 23.4794 26.8789C26.5626 26.9782 29.4931 28.2446 31.6782 30.4221C33.8632 32.5995 35.1399 35.5255 35.25 38.6083L11.75 38.6289Z" fill="#FF7A4A"/>
                                        </svg>
                                        <div>Sunrise<br>{{ result.advanced_details.sun_rise }}</div>
                                    </div>
                                    <div class="flex-sc gap-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="48" viewBox="0 0 47 48" fill="none">
                                            <path d="M29.375 32.8164L26.4375 32.8164L26.4375 26.9414L20.5625 26.9414L20.5625 32.8164L17.625 32.8164L23.5 41.6289L29.375 32.8164Z" fill="#FF7A4A"/>
                                            <path d="M36.4837 17.5377L38.775 12.1914L32.9 11.5158L32.3125 5.72891L26.9663 8.02016L23.5 3.37891L20.0338 8.02016L14.6875 5.72891L14.0119 11.6039L8.225 12.1914L10.5162 17.5377L5.875 21.0039H0V23.9414H47V21.0039H41.125L36.4837 17.5377ZM11.75 21.0039C11.8544 17.9243 13.1231 14.999 15.3 12.8182C17.4769 10.6375 20.4 9.3637 23.4794 9.25391C26.5626 9.3532 29.4931 10.6196 31.6782 12.7971C33.8632 14.9745 35.1399 17.9005 35.25 20.9833L11.75 21.0039Z" fill="#FF7A4A"/>
                                        </svg>
                                        <div>Sunset<br>{{ result.advanced_details.sun_set }}</div>
                                    </div>
                                    <div class="flex-sc gap-2">
                                        <img src="/app-assets/images/moonrise.png" alt="moonrise" class="img-fluid" style="width: 36px;height: 36px;">
                                        <div>Moonrise<br>{{ result.advanced_details.moon_rise }}</div>
                                    </div>
                                    <div class="flex-sc gap-2">
                                        <img src="/app-assets/images/moonset.png" alt="moonrise" class="img-fluid" style="width: 36px;height: 36px;">
                                        <div>Moonset<br>{{ result.advanced_details.moon_set }}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <h5 class="my-3">Zodiac Details</h5>
                                        <table class="table kundli-table">
                                            <tbody>
                                                <tr>
                                                    <td class="bg">Zodiac</td>
                                                    <td>{{ result.rasi.name }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Day</td>
                                                    <td>{{ result.advanced_details.vaara }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Moon Yogini Nivas</td>
                                                    <td>{{ result.advanced_details.moon_yogini_nivas }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-lg-6">
                                        <h5 class="my-3">Timing Details</h5>
                                        <table class="table kundli-table">
                                            <tbody>
                                                <tr>
                                                    <td class="bg">Rahu Kaal</td>
                                                    <td>{{ result.rahukaal }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Gulika</td>
                                                    <td>{{ result.gulika }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Yama Kanta</td>
                                                    <td>{{ result.yamakanta }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-12">
                                        <h5 class="my-3">Day Details</h5>
                                        <div class="table-responsive">
                                            <table class="table kundli-table text-nowrap">
                                                <tbody>
                                                    <tr>
                                                        <td><b>Title</b></td>
                                                        <td><b>Name</b></td>
                                                        <td><b>Diety</b></td>
                                                        <td><b>Start</b></td>
                                                        <td><b>End</b></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bg">Tithi</td>
                                                        <td>{{ result.tithi.name }}</td>
                                                        <td>{{ result.tithi.diety }}</td>
                                                        <td>{{ result.tithi.start }}</td>
                                                        <td>{{ result.tithi.end }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bg">Nakshatra</td>
                                                        <td>{{ result.nakshatra.name }}</td>
                                                        <td>{{ result.nakshatra.diety }}</td>
                                                        <td>{{ result.nakshatra.start }}</td>
                                                        <td>{{ result.nakshatra.end }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bg">Karana</td>
                                                        <td>{{ result.karana.name }}</td>
                                                        <td>{{ result.karana.diety }}</td>
                                                        <td>{{ result.karana.start }}</td>
                                                        <td>{{ result.karana.end }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bg">Yoga</td>
                                                        <td>{{ result.yoga.name }}</td>
                                                        <td>{{ result.yoga.diety || '-' }}</td>
                                                        <td>{{ result.yoga.start }}</td>
                                                        <td>{{ result.yoga.end }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="5">Ayanamsa @ {{ result.ayanamsa.name }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <h5 class="my-3">Masa Details</h5>
                                        <table class="table kundli-table">
                                            <tbody>
                                                <tr>
                                                    <td class="bg">Ayana</td>
                                                    <td>{{ result.advanced_details.masa.ayana }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Purnimanta</td>
                                                    <td>{{ result.advanced_details.masa.purnimanta_name }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Amanta</td>
                                                    <td>{{ result.advanced_details.masa.amanta_name }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Adhik Maasa</td>
                                                    <td>{{ result.advanced_details.masa.adhik_maasa ? 'Yes' : 'No' }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Ritu</td>
                                                    <td>{{ result.advanced_details.masa.ritu }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Ritu (Tamil)</td>
                                                    <td>{{ result.advanced_details.masa.ritu_tamil }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-lg-6">
                                        <h5 class="my-3">Samvatsara(Year) Details</h5>
                                        <table class="table kundli-table">
                                            <tbody>
                                                <tr>
                                                    <td class="bg">Kali</td>
                                                    <td>{{ result.advanced_details.years.kali }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Kali Samvaat Name</td>
                                                    <td>{{ result.advanced_details.years.kali_samvaat_name }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Saka</td>
                                                    <td>{{ result.advanced_details.years.saka }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Saka Samvaat Name</td>
                                                    <td>{{ result.advanced_details.years.saka_samvaat_name }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Vikram Samvaat</td>
                                                    <td>{{ result.advanced_details.years.vikram_samvaat }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Vikram Samvaat Name</td>
                                                    <td>{{ result.advanced_details.years.vikram_samvaat_name }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-lg-6">
                                        <h5 class="my-3">Other Details</h5>
                                        <table class="table kundli-table">
                                            <tbody>
                                                <tr>
                                                    <td class="bg">Abhijit Muhurt Start</td>
                                                    <td>{{ result.advanced_details.abhijit_muhurta.start }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Abhijit Muhurt End</td>
                                                    <td>{{ result.advanced_details.abhijit_muhurta.end }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Next Full Moon</td>
                                                    <td>{{ result.advanced_details.next_full_moon }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="bg">Next New Moon</td>
                                                    <td>{{ result.advanced_details.next_new_moon }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    props: ['user','api_langs'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            errors: [],
            err: false,
            result: null,
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            disBtn: false,
            cities: [],
            min_date: moment('1920', 'YYYY').startOf('year').format('YYYY-MM-DD'),
            max_date: moment('2030', 'YYYY').endOf('year').format('YYYY-MM-DD'),
            city_search: false,
            autocompleteFields : ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity'],
            form: new Form({
                lang: 'en',
                date: '',
                time: '07:00', 
                city: '',
                // pob: '',
                lat: '',
                lon: '',
                tz: '',
            }),
       };
    },
    created() {
        this.form.date = moment().format('YYYY-MM-DD')
        if(this.token) {
            this.form.lang = this.user.language
        }
    },
    mounted()  {
        this.emitter.emit('scroll_section')
        this.debouncedSearch = this.$debounce(this.search, 500);
        // this.emitter.emit('animate_background')
    },
    computed: {
        cities_array() {
            return this.cities.map((e,v) => {
                return {label: e.full_name, value: v}
            }) 
        },
    },
    methods: {
        search() {
            this.city_search = true
            axios.post('/api/user/get-cities', {city : this.form.city }).then((res) => {
                this.city_search = false
                this.cities = res.data.response || []
            }).catch((err) => {
                this.city_search = false
                this.err = true
            })
        },
        putCity() {
            var city = this.cities.find( i => i.full_name == this.form.pob) ;
            this.form.lat = city.coordinates[0]
            this.form.lon = city.coordinates[1]
            this.form.tz = city.tz
        },
        getAddressData(place) {
            this.form.lat = place.geometry.location.lat()
            this.form.lon = place.geometry.location.lng()
            this.form.tz = this.m2Tz(place.utc_offset_minutes)
            this.form.city = place.formatted_address
        },
        async submit() {
            if(!this.form.lat || !this.form.lon) {
                toast.fire({'icon': 'info', 'title': 'Please Select Location from Dropdown!'})
                return ;
            }
            this.disBtn = 1 ;
            this.err = false
            await axios.post('/api/user/get-panchang-result', this.form).then((res) => {
                this.disBtn = false ;
                if(res.data.status == 'success') {
                    this.result = res.data.result 
                    this.err = false
                }
                else {
                    toast.fire({'icon': 'error', 'title': 'Server Error Occured!'})
                    this.err = true
                }
            }).catch((e) => {
                if (e.response.status === 422) this.errors = e.response.data.errors;
                this.disBtn = false ;
                this.err = 'Server Error Occured!'
            })
        },
    }
}
</script>
<style scoped>
.flip-enter-active, .flip-leave-active {
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.flip-enter-from {
    transform: translateY(100%);
    opacity: 0;
    filter: blur(2px);
    
}
.flip-leave-to {
    transform: translateY(-100%);
    opacity: 0;
    filter: blur(2px);
}
</style>