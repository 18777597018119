<template>
    <div class="main">
        <section class="section orange py-3">
            <div class="container">
                <div class="row">
                    <div class="col-10 offset-1 col-lg-10 offset-lg-1">
                        <p class="title" :class="{'m-0': mobile}">
                            Welcome to <b>Shubh Kal</b>, your friendly guide to finding the best times of day! We help you discover the right moments to start important activities based on your location. Planning something special like a new business, big decisions, or important events? We'll show you the good times to act and which times to avoid. Think of us as your daily planner that works with traditional timing wisdom to help things go smoothly. Let us help you pick the perfect time for your important moments at <b>Shubh Kal</b>.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section orange py-3 py-lg-2">
            <!-- <div class="animated-background"></div> -->
            <div class="container">
                <form class="row" @submit.prevent="submit()">
                    <div class="col-12">
                        <div class="card">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group orange">
                                        <label class="mb-2">Date</label>
                                        <VueDatePicker hide-offset-dates :min-date="min_date" :max-date="max_date" :year-range="[1920, 2050]" prevent-min-max-navigation :clearable="false" format="dd MMM, yyyy" v-model="form.date" model-type="yyyy-MM-dd" :format="day_name" :month-change-on-scroll="false" :enable-time-picker="false"></VueDatePicker>
                                        <span class="invalid-feedback" v-if="errors.date"><strong>{{ errors.date[0] }}</strong></span>
                                    </div>
                                </div>
                                <div class="col-lg-6 form-group orange">
                                    <label>Search Location</label>
                                    <GMapAutocomplete @placeholder="'Search Location'" @place_changed="getAddressData" :types="['geocode']" :fields="autocompleteFields" class="form-control" ></GMapAutocomplete>
                                    <!-- <input type="text" class="form-control" @paste.prevent required v-model="form.city" @input="debouncedSearch"> -->
                                </div>
                                <!-- <transition-group name="fade">
                                    <div key="loading" class="col-lg-2" v-if="city_search">
                                        <loader text="Loading. . ." :small="true" />
                                    </div>
                                    <div v-if="cities_array.length" key="dropdown" class="col-lg-5 form-group orange">
                                        <label>Select City</label>
                                        <v-select :options="cities_array" :clearable="false" @option:selected="putCity" v-model="form.pob" :reduce="i => i.label"></v-select>
                                    </div>
                                </transition-group> -->
                                <div class="col-lg-2 flex-sc">
                                    <button type="submit" :disabled="disBtn == 1" class="btn dark mt-3">
                                        <i v-if="disBtn == 1" class="fas fa-spinner fa-spin"></i>
                                        <transition name="flip" mode="out-in">
                                            <span v-if="!disBtn">Get Result</span>
                                            <span v-else>Loading . . .</span>
                                        </transition>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
        <section class="section orange py-3" ref="result">
            <div class="container">
                <transition name="fade" appear>
                    <div class="row" v-if="result">
                        <div class="col-12 col-lg-6">
                            <div class="kal-head mt-0" :class="{'w-75' : !mobile}">
                                <div class="wrapper"><img src="/app-assets/images/day-kal.png" alt=""></div>
                                <div class="content"><div class="heading">Day Kal</div></div>
                            </div>
                            <transition-group tag="div" appear :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave">
                                <div class="kal-timing" :class="{'good': i.good}" :data-index="index" :key="index" v-for="(i,index) in result.first_half">
                                    {{ i.start +' - '+ i.end }}
                                </div>
                            </transition-group>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="kal-head" :class="{'reverse w-75' : !mobile}">
                                <div class="wrapper"><img src="/app-assets/images/night-kal.png" alt=""></div>
                                <div class="content"><div class="heading">Night Kal</div></div>
                            </div>
                            <transition-group tag="div" appear :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave">
                                <div class="kal-timing" :class="{'good': i.good}" :data-index="index" :key="index" v-for="(i,index) in result.second_half">
                                    {{ i.start +' - '+ i.end }}
                                </div>
                            </transition-group>
                        </div>
                    </div>
                </transition>
                <div class="col-10 offset-1 col-lg-10 offset-lg-1">
                    <p class="title" :class="{'m-0': mobile}">
                        Please note: The auspicious timings provided here are general predictions suitable for starting various activities in common situations. These timings are applicable in general circumstances and locations. For personalized and specific inquiries tailored to your unique circumstances, we recommend consulting or speaking with an astrologer. They can provide insights and guidance tailored to your individual needs and concerns. Your cosmic journey is unique, and personalized advice can help you navigate it more effectively.
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s linear;
}
.fade-enter-from, .fade-leave-to {
    filter: blur(5px) ;
    opacity: 0 ;
}
.flip-enter-active, .flip-leave-active {
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.flip-enter-from {
    transform: translateY(100%);
    opacity: 0;
    filter: blur(2px);
    
}
.flip-leave-to {
    transform: translateY(-100%);
    opacity: 0;
    filter: blur(2px);
}
</style>
<script>
export default {
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            errors: [],
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            location: null,
            city_search: false,
            disBtn: false,
            err: false,
            min_date: moment('1920', 'YYYY').startOf('year').format('YYYY-MM-DD'),
            max_date: moment('2050', 'YYYY').endOf('year').format('YYYY-MM-DD'),
            cities: [],
            autocompleteFields : ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'photo', 'place_id', 'scope', 'type', 'url', 'vicinity'],
            form: new Form({
                date: moment().format('YYYY-MM-DD'),
                lat: "",
                lon: "",
                // city: '',
                // pob: '',
            }),
            result: null,
       };
    },
    async created(){
        // await this.position();
    },
    computed: {
        cities_array() {
            return this.cities.map((e,v) => {
                return {label: e.full_name, value: v}
            }) 
        },
    },
    mounted() {
        // console.log(this.$refs.section);
        this.emitter.emit('scroll_section')
        // this.debouncedSearch = this.$debounce(this.search, 500);
        // this.emitter.emit('animate_background')
    },
    methods: {
        search() {
            this.city_search = true
            axios.post('/api/user/get-cities', {city : this.form.city }).then((res) => {
                this.city_search = false
                this.cities = res.data.response || []
            }).catch((err) => {
                this.city_search = false
                this.err = true
            })
        },
        putCity() {
            var city = this.cities.find( i => i.full_name == this.form.pob) ;
            this.form.lat = city.coordinates[0]
            this.form.lon = city.coordinates[1]
        },
        getAddressData(place) {
            this.form.lat = place.geometry.location.lat()
            this.form.lon = place.geometry.location.lng()
            // this.form.city = place.formatted_address
            // this.form.tz = this.m2Tz(place.utc_offset_minutes)
        },
        day_name() {
            return moment(this.date).format('DD MMMM, YYYY - ddd')
        },
        onBeforeEnter(el) {
            el.style.opacity = 0
        },
        onEnter(el, done) {
            gsap.to(el, {
                opacity: 1,
                delay: el.dataset.index * 0.15,
                onComplete: done
            })
        },
        onLeave(el, done) {
            gsap.to(el, {
                opacity: 0,
                delay: el.dataset.index * 0.15,
                onComplete: done
            })
        },
        async submit() {
            if(!this.form.lat || !this.form.lon) {
                toast.fire({'icon': 'info', 'title': 'Please Select Location from Dropdown!'})
                return ;
            }
            this.disBtn = 1
            axios.post('/api/user/ardh-pahar/get', this.form, this.config).then(async (res) => {
                this.result = {
                    first_half: res.data.first_half,
                    second_half: res.data.second_half
                } ;
                this.disBtn = false
                this.$refs.result.scrollIntoView({behavior: 'smooth'})
            }).catch((err) => {
                this.disBtn = false
            })
        },
    }
}
</script>