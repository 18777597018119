<template>
    <section class="section orange" ref="section">
        <!-- <div class="animated-background"></div> -->
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <transition name="fade" mode="out-in">
                        <div class="card header mb-0" v-if="!loading">
                            <div class="card-header">
                                <div class="left-content">
                                    <div class="icon">
                                        <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.01514 12.2832C7.01514 10.6264 8.35828 9.28323 10.0151 9.28323H41.9318C42.2079 9.28323 42.4318 9.50709 42.4318 9.78323V15.6166C42.4318 16.7211 41.5364 17.6166 40.4318 17.6166H10.0151C8.35829 17.6166 7.01514 16.2734 7.01514 14.6166V12.2832Z" fill="#38210F"/>
                                            <path d="M7.01514 38.6166V13.6166C7.01514 15.8257 8.806 17.6166 11.0151 17.6166H42.5151C43.6197 17.6166 44.5151 18.512 44.5151 19.6166V27.8832C44.5151 27.9661 44.448 28.0332 44.3651 28.0332H36.0985C34.9939 28.0332 34.0985 28.9287 34.0985 30.0332V34.3666C34.0985 35.4711 34.9939 36.3666 36.0985 36.3666H44.3651C44.448 36.3666 44.5151 36.4337 44.5151 36.5166V40.6166C44.5151 41.7211 43.6197 42.6166 42.5151 42.6166H11.0151C8.806 42.6166 7.01514 40.8257 7.01514 38.6166Z" fill="#FFF384"/>
                                            <path d="M32.0151 30.0332C32.0151 28.9287 32.9106 28.0332 34.0151 28.0332H44.3651C44.448 28.0332 44.5151 28.1004 44.5151 28.1832V36.2166C44.5151 36.2994 44.448 36.3666 44.3651 36.3666H34.0151C32.9106 36.3666 32.0151 35.4711 32.0151 34.3666V30.0332Z" fill="#38210F"/>
                                            <rect x="5.76514" y="10.9499" width="6" height="1" rx="0.5" fill="#38210F"/>
                                        </svg>
                                    </div>
                                    <span>WALLET<br>BALANCE</span>
                                </div>
                                <div class="right-content">
                                    <h4>
                                        <transition name="fade" mode="out-in">
                                            <span :key="user.wallet_amount">{{ user.currency.symbol+user.wallet_amount }}</span>
                                        </transition>
                                    </h4>
                                </div>
                            </div>
                            <div class="card-body">
                                <h6 class="mb-4 text-body text-uppercase">Add Money to wallet</h6>
                                <form @submit.prevent="add_money()" class="row">
                                    <div class="col-12 col-md-6 col-lg-3 flex-sc align-items-start flex-column orange required">
                                        <div class="form-group input-group" :class="{'w-auto': !mobile}">
                                            <div class="input-group-append">
                                                <button type="button" class="btn orange-dark left">{{ user?.currency?.symbol }}</button>
                                            </div>
                                            <input id="amount" type="tel" placeholder="Enter Amount" @keypress="validate" v-model="add_amount" pattern="[0-9]+(\.[0-9]{1})?%?" class="form-control" required>
                                        </div>
                                        <p class="text-body cursor-pointer" :class="{'mb-3': mobile}" @click="show_promos = !show_promos">Have a Promocode?</p>
                                    </div>
                                    <transition name="grow">
                                        <div class="col-12 form-group" v-if="show_promos && mobile">
                                            <div class="card mb-2">
                                                <form @submit.prevent="apply_promocode()" class="row">
                                                    <div class="col-12 col-lg-4">
                                                        <div class="form-group input-group">
                                                            <input type="text" v-model="promocode" placeholder="Enter Promocode" class="form-control" required>
                                                            <div class="input-group-append">
                                                                <button type="submit" :disabled="disBtn == 'apply'" class="btn dark">Apply</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                </form>
                                                <h5>Promocodes Available</h5>
                                                <div class="scroll-x-container">
                                                    <div class="promo" v-for="i in 5" :key="i">{{ i }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                    <div class="col-12 col-md-6 col-lg-3 form-group">
                                        <button :disabled="disBtn == 'add'" type="submit" class="btn dark block">
                                            <i v-if="disBtn == 'add'" class="fas fa-spinner fa-spin"></i>
                                            <transition name="flip" mode="out-in">
                                                <span v-if="disBtn == 'add'">Loading . . .</span>
                                                <span v-else>Add Money</span>
                                            </transition>
                                        </button>
                                    </div>
                                </form>
                                <!-- <div class="form-group">
                                    <div class="dropbox">
                                        <h6>Popular Topups<hr></h6>
                                        <div class="content">
                                            <div class="checks" v-for="(i,index) in topups" :key="index">
                                                <input type="radio" @click="add_amount(i)" name="topups" :id="'check_'+index">
                                                <label :for="'check_'+index">{{ i }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <transition name="grow">
                                    <div class="card my-4" v-if="show_promos && !mobile">
                                        <form @submit.prevent="apply_promocode()" class="row">
                                            <div class="col-12 col-lg-4">
                                                <div class="form-group input-group">
                                                    <input type="text" v-model="promocode" placeholder="Enter Promocode" class="form-control" required>
                                                    <div class="input-group-append">
                                                        <button type="submit" :disabled="disBtn == 'apply'" class="btn dark">Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                        </form>
                                        <h5>Promocodes Available</h5>
                                        <div class="scroll-x-container">
                                            <div class="promo" v-for="i in 5" :key="i">{{ i }}</div>
                                        </div>
                                    </div>
                                </transition>
                            </div>
                            <div class="card-footer d-block">
                                <table class="table transaction-table" v-if="!mobile">
                                    <thead>
                                        <tr>
                                            <th>Transactions</th>
                                            <th>Amount</th>
                                            <th>Transaction On</th>
                                            <th>status</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <transition-group name="grow" appear>
                                            <tr v-for="(t,index) in transactions" :key="index">
                                                <td><div class="transaction-td">
                                                    <div class="icon">
                                                        <img :src="'/app-assets/images/services/'+images(t.sub_type)" alt="Transaction logo">
                                                    </div>
                                                    <div class="content flex-cc">
                                                        <span v-if="t.type=='credit'" class="text-uppercase p-title">{{ t.notes }}</span>
                                                        <span v-else class="text-uppercase p-title">{{ t.sub_type?.replace('_', ' ') }}</span>
                                                        <!-- <span class="t-id">Transaction ID: {{ t.payment_id }}</span> -->
                                                    </div>
                                                </div></td>
                                                <td>{{ t.type=='credit' ? '+':'-' }}{{ t.currency.symbol+t.amount }}</td>
                                                <td>{{ $filters.timestamp(t.created_at) }}</td>
                                                <td class="text-capitalize">{{ t.status }}</td>
                                                <td><router-link :to="'/transaction/'+t.crypt_id" class="btn small dark">View</router-link></td>
                                            </tr>
                                        </transition-group>
                                        <tr v-if="!transactions.length">
                                            <td colspan="5"><div class="transaction-td d-block">
                                                <div class="text-uppercase text-center p-title">No Transactions were found!</div>
                                            </div></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table transaction-table" v-else>
                                    <tbody>
                                        <transition-group name="grow" appear>
                                            <tr v-for="(t,index) in transactions" :key="index">
                                                <td><div class="transaction-td">
                                                    <div>
                                                        <div class="icon">
                                                            <img :src="'/app-assets/images/services/'+images(t.sub_type)" alt="Transaction logo">
                                                        </div>
                                                    </div>
                                                    <div class="content">
                                                        <span class="text-uppercase p-title">{{ t.notes }}</span>
                                                        <span class="text-uppercase">{{ $filters.timestamp(t.created_at) }}</span>
                                                        <!-- <span class="t-id">Txn ID: {{ t.payment_id }}</span> -->
                                                    </div>
                                                    <div class="amount">
                                                        {{ t.type=='credit' ? '+':'-' }}{{ t.currency.symbol+t.amount }}
                                                    </div>
                                                </div></td>
                                            </tr>
                                        </transition-group>
                                    </tbody>
                                </table>
                                <div class="row">
                                    <div class="col-12">
                                        <!-- <l-pagination :paginator="pagination" :mobile="mobile" :pageName="'wallet'" v-if="transactions.length" /> -->
                                        <nav key="Page navigation" aria-label="Page navigation" v-if="transactions.length">
                                            <ul class="custom-pagination" v-if="pagination?.pageCount > 8 || mobile">
                                                <li class="page-item prev-item" @click="pageNo = (pageNo > 1) ? pageNo - 1 : pagination?.pageCount"><i class="fas fa-sm fa-long-arrow-left"></i></li>
                                                <li class="page-item" :class="{ 'active' : pageNo==1 }" @click="pageNo=1"><a class="page-link" href="#">1</a></li>
                                                <li class="divider" :class="{ 'active' : pageNo > 1 && pageNo < pagination?.pageCount }"></li>
                                                <li class="page-item" :class="{ 'active' : pageNo==pagination?.pageCount }" @click="pageNo = pagination?.pageCount"><a class="page-link" href="#">{{ pagination?.pageCount }}</a></li>
                                                <li class="page-item next-item" @click="pageNo = pageNo < pagination?.pageCount ? pageNo+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                            </ul>
                                            <ul class="custom-pagination" v-else>
                                                <li class="page-item prev-item" @click="pageNo = (pageNo > 1) ? pageNo - 1 : pagination?.pageCount"><i class="fas fa-long-arrow-left"></i></li>
                                                <li class="page-item" :class="{'active' : pageNo == i}" v-for="i in pagination?.pageCount" @click="pageNo=i" :key="i"><a class="page-link" href="#">{{ i }}</a></li>
                                                <li class="page-item next-item" @click="pageNo = pageNo < pagination?.pageCount ? pageNo+1 : 1"><i class="fas fa-sm fa-long-arrow-right"></i></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <loader v-else></loader>
                    </transition>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all 0.2s ease-in;
}

.fade-enter-from, .fade-leave-to {
    filter: blur(3px);
    opacity: 0.5;
}
.grow-enter-active, .grow-leave-active {
    transition: all 0.25s ease-out;
}
.grow-enter-from {
    opacity: 0;
    filter: blur(15px);
    transform: scale(0);
    transform-origin: center;
}
.grow-leave-to {
    opacity: 0;
    filter: blur(15px);
    transform: scale(0);
    transform-origin: center;
}
.flip-enter-active, .flip-leave-active {
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.flip-enter-from {
    transform: translateY(100%);
    opacity: 0;
    filter: blur(2px);
    
}
.flip-leave-to {
    transform: translateY(-100%);
    opacity: 0;
    filter: blur(2px);
}
</style>
<script>
export default {
    props: ['user'],
    data() {
        return {
            token: this.isLogged(),
            mobile: this.isMobile(),
            config: { headers: { Authorization: `Bearer ${this.isLogged()}` } },
            errors: [],
            transactions: [],
            show_promos: false,
            promocode: '',
            add_amount: '',
            disBtn: false,
            loading: false,
            pagination: null,
            pageNo: 1,
            topups: ['51', '101', '201', '501', '1001', '3001', '5001'],
        };
    },
    created() {
        this.emitter.emit('talk_section', true)
        this.emitter.emit('blog_section', true)

        var sc = document.createElement("script");
        sc.setAttribute("src", "https://checkout.razorpay.com/v1/checkout.js");
        sc.setAttribute("type", "text/javascript");
        document.head.appendChild(sc);

        // this.$nextTick(() => {
        //     this.emitter.emit('animate_background')
        // })
        this.emitter.on('page-changed', (data) => {
            if(data[1] == 'wallet')
                this.pageNo = data[0]
        })
        if(this.token) {
            this.index()
        } else this.loading = true
    },
    beforeUnmount() {
        this.emitter.emit('talk_section', false)
        this.emitter.emit('blog_section', false)
    },
    watch: {
        pageNo (n,o) { 
            this.index() 
        },
    },
    methods: {
        index() {
            axios.get('/api/user/wallet-transactions?page='+this.pageNo, this.config).then((res) => { 
                this.transactions = Object.values(res.data.transactions.data)
                this.pagination = {
                    "page": res.data.transactions.current_page,
                    "pageSize": res.data.transactions.per_page,
                    "pageCount": res.data.transactions.last_page,
                    "total": res.data.transactions.total
                }
                // this.emitter.emit('scroll_section')
                // this.transactions = res.data.transactions
                this.$Progress.finish() ;
            }).catch(error => toast.fire({'icon': 'error', 'title': 'Error Occured!'})); 
        },
        apply_promocode(code = null) {
            if(!code) code = this.promocode
            console.log(code);
        },
        add_money(amount = null) {
            this.disBtn = 'add'
            if(!amount) amount = this.add_amount
            var options = {
                "amount": this.add_amount*100 , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": this.user.currency.code,
                "key": import.meta.env.VITE_RZP_KEY,
                "name": this.$appname,
                "description": this.$appname,
                "handler": (response) => {
                    this.$Progress.start() ;
                    axios.post('/api/user/payment-success', {'order_id': response.razorpay_payment_id }, this.config).then((res) => { 
                        this.$Progress.finish() ;
                        this.emitter.emit('get_user')
                        this.disBtn = false
                        this.index()
                        this.add_amount = ''
                    }).catch((error) => {
                        console.log(error);
                        this.disBtn = false
                        this.$Progress.fail() ;
                        toast.fire({'icon': 'error', 'title': 'Error Occured!'})
                    }); 
                },
                "modal": {
                    "ondismiss": () => {
                        this.$Progress.fail() ;
                        toast.fire({'icon': 'info', 'title': 'Payment Cancelled!'})
                        this.disBtn = false
                    }
                },  
                "prefill": {
                    "email": this.user.email,
                    "contact": this.user.mobile,
                },
                "theme": {
                    "color": "#4c2d15"
                }
            };
            var rzp = new Razorpay(options);
            rzp.on('payment.failed', function (response){
                this.$Progress.fail() ;
                this.disBtn = false
                toast.fire({'icon': 'error', 'title': 'Payment Failed!'})
            });
            rzp.open();
        },
        images(type) {
            switch (type) {
                case 'kundli' : return 'kundli.png' ;
                case 'kundli_pdf' : return 'kundli.png' ;
                case 'matchmaking' : return 'kundli-matchmaking.png' ;
                case 'matchmaking_pdf' : return 'kundli-matchmaking.png' ;
                case 'rudraksh_gemstone' : return 'rudraksh-gemstone.png' ;
                case 'numerology' : return 'numerology.png' ;
                case 'chat' : return 'chat.png' ;
                case 'audio_call' : return 'audio_call.png' ;
                case 'video_call' : return 'video_call.png' ;
                case 'puja_booking' : return 'astro-pujas.png' ;
                case null : return 'money_add.png' ;
                default: break;
            }
        },
        fetchPage(val) {
            this.pageNo = val
        }
    }
}
</script>