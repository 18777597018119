<template>
    <nav v-if="paginator && paginator.total > 0" class="d-flex justify-items-center justify-content-between">
        <!-- Small screen pagination -->
        <div class="d-flex justify-content-between flex-fill d-sm-none">
            <ul class="pagination">
                <!-- Previous Page Link -->
                <li :class="['page-item', { disabled: paginator.current_page === 1 }]"
                    :aria-disabled="paginator.current_page === 1">
                    <a v-if="paginator.current_page !== 1" class="page-link" :href="paginator.prev_page_url"
                        @click.prevent="changePage(paginator.current_page - 1)">
                        Previous
                    </a>
                    <span v-else class="page-link">Previous</span>
                </li>

                <!-- Next Page Link -->
                <li :class="['page-item', { disabled: !paginator.next_page_url }]"
                    :aria-disabled="!paginator.next_page_url">
                    <a v-if="paginator.next_page_url" class="page-link" :href="paginator.next_page_url"
                        @click.prevent="changePage(paginator.current_page + 1)">
                        Next
                    </a>
                    <span v-else class="page-link">Next</span>
                </li>
            </ul>
        </div>

        <!-- Large screen pagination -->
        <div class="d-none flex-sm-fill d-sm-flex align-items-sm-center justify-content-sm-between">
            <div>
                <p class="small text-muted">
                    Showing
                    <span class="fw-semibold">{{ paginator.from }}</span>
                    to
                    <span class="fw-semibold">{{ paginator.to }}</span>
                    of
                    <span class="fw-semibold">{{ paginator.total }}</span>
                    results
                </p>
            </div>

            <div>
                <ul class="pagination">
                    <!-- Previous Page Link -->
                    <li :class="['page-item', { disabled: paginator.current_page === 1 }]"
                        :aria-disabled="paginator.current_page === 1">
                        <a v-if="paginator.current_page !== 1" class="page-link" href="#"
                            @click.prevent="changePage(paginator.current_page - 1)">
                            &rsaquo;
                        </a>
                        <span v-else class="page-link" aria-hidden="true">&rsaquo;</span>
                    </li>

                    <!-- Pagination Elements -->
                    <template v-for="page in paginator.last_page" :key="page">
                        <li :class="['page-item', { active: page === paginator.current_page }]"
                            :aria-current="page === paginator.current_page ? 'page' : null">
                            <a v-if="page !== paginator.current_page" class="page-link" href="#"
                                @click.prevent="changePage(page)">{{ page }}</a>
                            <span v-else class="page-link">{{ page }}</span>
                        </li>
                    </template>

                    <!-- Next Page Link -->
                    <li :class="['page-item', { disabled: !paginator.next_page_url }]"
                        :aria-disabled="!paginator.next_page_url">
                        <a v-if="paginator.next_page_url" class="page-link" href="#"
                            @click.prevent="changePage(paginator.current_page + 1)">
                            &lsaquo;
                        </a>
                        <span v-else class="page-link" aria-hidden="true">&lsaquo;</span>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    props: {
        paginator: {
            type: Object,
            required: true
        },
        pageName: {
            type: String,
            required: true
        }
    },
    methods: {
        changePage(page) {
            this.emitter.emit('page-changed', [page,this.pageName]);
        }
    }
};
</script>